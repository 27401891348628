import React from "react";
import style from "./ourcommunity.module.css";
import { BsGoogle } from "react-icons/bs";
import { AiFillApple } from "react-icons/ai";
import { useSelector } from "react-redux";
const OurCommunity = ({ data }) => {
  const lang = useSelector((state) => state.langSlice.lang);

  return (
    <div className="bg mt-5">
      <div id="app" className="container ">
        <div className="row justify-content-center align-items-center">
          <div
            data-aos="fade-down"
            data-aos-offset="100"
            className="col-12 col-md-6 mb-4 mb-md-0"
          >
            <img
              src={data.JoinCommunityImg}
              alt="our-community/img"
              data-aos="zoom-in-down"
              data-aos-delay="300"
              className={style.mainImg}
            />
          </div>
          <div className="col-12 col-md-6 mb-4 mb-md-0 text-center">
            <div className="mb-5">
              <h5
                className="fw-bolder mb-3"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                {data.JoinOurCommunity_}
              </h5>
              <p
                className="text-black-50"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                {data.JoinCommunityDes_}
              </p>
            </div>
            <div className="pt-5 d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">
              <a
                className={style.box}
                target="_blank"
                rel="noreferrer"
                href={data.playStore}
              >
                <BsGoogle size={20} />
                <span>{lang === "en" ? " play Store" : "متجر play"}</span>
              </a>
              <a
                className={style.box}
                target="_blank"
                rel="noreferrer"
                href={data.appleStore}
              >
                <AiFillApple size={20} />{" "}
                <span>{lang === "en" ? " App Store" : "متجر app"}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCommunity;
