import React, { useEffect, useState } from "react";
import style from "./orderCards.module.css";
import arrow from "../../../assets/tallArrow.svg";
import axios from "axios";
const apiUrl = "https://fittnesstest.technomasr.com/api";
const OrdersCards = ({ dataa, lang, user }) => {
  const [activeId, setActiveId] = useState(null);
  useEffect(() => {
    window.localStorage.setItem("userId", user);
  }, [user]);
  const handleButtonClick = async () => {
    try {
      // Make API call to get the external link based on the order ID
      const response = await axios.post(`${apiUrl}/stripe`, null, {
        headers: {
          user,
          orderId: activeId,
          lang,
        },
      });
      const { data } = response;

      // Open the external link in a new tab/window
      window.open(data.url, "_blank");
    } catch (error) {
      console.error("Error fetching external link:", error);
    }
  };
  return (
    <div className="py-3 hh">
      {dataa.length ? (
        dataa.map((item, index) => (
          <div
            onClick={() => setActiveId(item.orderDetails.id)}
            className={`pointer d-flex align-items-center justify-content-between flex-column flex-md-row gap-5 gap-md-0 mb-3 p-3 ${
              style.mainContainer
            } ${activeId === index ? style.active : null} `}
            key={index}
          >
            <div>
              <div className="d-flex align-items-center gap-3 mb-3">
                <p className={`m-0 p-0 ${style.hash}`}>
                  {item.orderDetails.id} -
                </p>
                <p className={`m-0 p-0 ${style.title} `}>
                  {item.orderDetails?.workout?.name_}
                </p>
              </div>
              <div className="mb-4 d-flex align-items-center gap-5">
                <p className={`m-0 p-0 ${style.numberOf}`}>
                  number of weeks :{" "}
                  <span className={`mx-2 my-0 p-0 ${style.info}`}>
                    {item.orderDetails.workout.weeks_no
                      ? item.orderDetails.workout.weeks_no
                      : item.orderDetails.workout.workout.weeks_no}
                  </span>
                </p>
                <p className={`m-0 p-0 ${style.numberOf}`}>
                  number of sessions :{" "}
                  <span className={`mx-2 my-0 p-0 ${style.info}`}></span>
                </p>
                <p className={`m-0 p-0 ${style.numberOf}`}>
                  number of exercises :{" "}
                  <span className={`mx-2 my-0 p-0 ${style.info}`}></span>
                </p>
              </div>
              <div className="d-flex align-items-center gap-5">
                <p className={`m-0 p-0 ${style.numberOf}`}>
                  fees :{" "}
                  <span className={`mx-2 my-0 p-0 ${style.info}`}>
                    {item.orderDetails.cost} $
                  </span>
                </p>
                <p className={`m-0 p-0 ${style.numberOf}`}>
                  type :{" "}
                  <span className={`mx-2 my-0 p-0 ${style.info}`}>
                    {item.orderDetails.type}
                  </span>
                </p>
                <p className={`m-0 p-0 ${style.numberOf}`}>
                  payment status :{" "}
                  <span className={`mx-2 my-0 p-0 ${style.info}`}>
                    {item.orderDetails.status}
                  </span>
                </p>
              </div>
            </div>
            <button
              onClick={handleButtonClick}
              disabled={activeId === null}
              className={`${style.btn} ${
                activeId === item.orderDetails.id
                  ? style.showBtn
                  : style.hideBtn
              }`}
            >
              <span>check out</span>
              <img
                loading="lazy"
                alt="arrow/icon"
                src={arrow}
                className={style.arrow}
              />
            </button>
          </div>
        ))
      ) : (
        <p className="fw-bolder mt-5 pt-5 fs-3 text-center my-3">
          no orders yet
        </p>
      )}
    </div>
  );
};

export default OrdersCards;
/**
 *
 *
 */
