import React from "react";
import Hero from "../Components/utilites/hero/Hero";
import MainTitle from "../Components/utilites/MainTitile/MainTitle";
import FilterByCategory from "../Components/workout/categories/filter/FilterByCategory";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const Workout = () => {
  const fetchData = () => {
    return request({ url: "/workouts/forProgrammes" });
  };
  const { data, isLoading } = useQuery("workout page", fetchData);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Hero link={data.data.workout_header.workout_header_img} />
          <div className="py-5">
            <MainTitle
              title={data.data.workout_header.workout_header_title}
              desc={data.data.workout_header.workout_header_des}
            />
            <FilterByCategory workoutData={data.data.data} />
          </div>
        </div>
      )}
    </>
  );
};

export default Workout;
