import React from "react";
import style from "./socialMedia.module.css";
import face from "../../../assets/icons8-facebook-48.png";
import pinteres from "../../../assets/icons8-pinterest-48.png";
import insta from "../../../assets/icons8-instagram-48.png";
import twitter from "../../../assets/icons8-twitter-48.png";
import tiktok from "../../../assets/icons8-tiktok-48.png";
import youtube from "../../../assets/icons8-youtube-48.png";
import snap from "../../../assets/icons8-snapchat-circled-logo-48.png";

const SocialMedia = ({ data }) => {
  console.log("data from social media component", data);
  return (
    <div className="d-flex justify-content-center gap-2 flex-wrap mb-5">
      {data?.facebook ? (
        <a target="_blank" rel="noreferrer" href={data.facebook}>
          <img alt="social/index" src={face} className={style.social} />
        </a>
      ) : null}
      {data?.twitter ? (
        <a target="_blank" rel="noreferrer" href={data.twitter}>
          <img alt="social/index" src={twitter} className={style.social} />
        </a>
      ) : null}
      {data?.instagram ? (
        <a target="_blank" rel="noreferrer" href={data.instagram}>
          <img alt="social/index" src={insta} className={style.social} />
        </a>
      ) : null}
      {data?.youtube ? (
        <a target="_blank" rel="noreferrer" href={data.youtube}>
          <img alt="social/index" src={youtube} className={style.social} />
        </a>
      ) : null}
      {data?.tiktok ? (
        <a target="_blank" rel="noreferrer" href={data.tiktok}>
          <img alt="social/index" src={tiktok} className={style.social} />
        </a>
      ) : null}
      {data?.Pinterest ? (
        <a target="_blank" rel="noreferrer" href={data.Pinterest}>
          <img alt="social/index" src={pinteres} className={style.social} />
        </a>
      ) : null}
      {data?.snapChat ? (
        <a target="_blank" rel="noreferrer" href={data.snapChat}>
          <img alt="social/index" src={snap} className={style.social} />
        </a>
      ) : null}
    </div>
  );
};

export default SocialMedia;
/*
  <div >
      {icons.map((item, index) => (
        <a key={index} target="_blank" href="www.google.com">
        
        </a>
      ))}
    </div>
*/
