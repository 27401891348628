import React from "react";
import CancellSmile from "../Components/cancel/CancellSmile";
import { useParams } from "react-router-dom";
import { request } from "../Components/utilites/axios";
import { useQuery } from "react-query";
const Cancel = () => {
  const userId = JSON.parse(window.localStorage.getItem("userId"));
  const params = useParams();
  const { id } = params;
  const fetchData = () => {
    return request({
      url: "/orderStatus",
      headers: {
        orderId: id,
        status: false,
        userId,
      },
    });
  };
  const { data, isLoading } = useQuery("success page", fetchData);
  return (
    <div>
      <CancellSmile />
    </div>
  );
};

export default Cancel;
