import React, { useState } from "react";
import style from "./loginForm.module.css";
import user from "../../assets/user.svg";
import passwordImg from "../../assets/password.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { request } from "../../Components/utilites/axios.js";
import Spinner from "../../Components/utilites/Spinner/Spinner";
const LoginForm = () => {
  const navigate = useNavigate();
  const [account, setAccount] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [password, setPassword] = useState("");
  const [showAccountIcon, setShowAccountIcon] = useState(true);
  const [showPasswordIcon, setShowPasswordIcon] = useState(true);
  const handleChange = (event) => {
    const value = event.target.value;
    setAccount(value);

    // Regular expression for email or phone validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]+$/;

    // Validate the input value against the regex patterns
    if (!emailRegex.test(value) && !phoneRegex.test(value)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    if (password.trim() !== "") {
      setShowPasswordIcon(false);
    } else {
      setShowPasswordIcon(true);
    }
  };
  const handleChangeAccount = (e) => {
    setAccount(e.target.value);
    if (account.trim() !== "") {
      setShowAccountIcon(false);
    } else {
      setShowAccountIcon(true);
    }
  };
  const handleLogin = (data) => {
    return request({ url: "/auth/login", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(handleLogin, {
    onSuccess: (data) => {
      if (data?.data.status !== "faild") {
        alert("done");
        console.log("this is login page", data.data);
        window.localStorage.setItem(
          "userId",
          JSON.stringify(data.data.data.id)
        );
        navigate("/");
        // window.location.reload();
      }
    },
    onError: (data) => {
      alert(data.data.message);
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (account.trim() === "" || password.trim() === "") {
      return;
    } else {
      const userData = { account, password };
      mutate(userData);
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <form>
          <div className="mb-3 auth_inp_container">
            <input
              placeholder="email"
              value={account}
              onChange={handleChangeAccount}
              className="auth_inp"
              type="text"
            />

            {showAccountIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={user}
              />
            )}
          </div>
          <div className="mb-3 auth_inp_container">
            <input
              placeholder="password"
              value={password}
              onChange={(e) => handleChangePassword(e)}
              className="auth_inp"
              type="password"
            />

            {showPasswordIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={passwordImg}
              />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <Link to="/forget" className={style.forget}>
              forget password ?
            </Link>
          </div>
          <div className=" my-3 d-flex justify-content-center a;ign-items-center">
            <button onClick={handleClick} className="btn rounded-pill">
              sign in
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default LoginForm;
