import React, { useState } from "react";
import passwordImg from "../../assets/password.svg";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { request } from "../../Components/utilites/axios.js";
const NewPasswordForm = () => {
  // hooks

  const navigate = useNavigate();
  // external state
  const account = JSON.parse(window.localStorage.getItem("account"));

  console.log("this is otp code from the final step", account);
  // component state
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const handleLogin = (data) => {
    return request({ url: "/auth/reset/password", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(handleLogin, {
    onSuccess: (data) => {
      if (data?.data.status) {
        alert("done");
        console.log("this is resetPasswoord page", data.data);
        navigate("/");
      }
    },
    onError: () => {
      alert("not done");
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (password_confirmation.trim() === "" || password.trim() === "") {
      return;
    } else {
      const userData = { account, password, password_confirmation };
      mutate(userData);
      console.log("this is user data", userData);
    }
  };
  return (
    <div>
      <div className="mb-2 auth_inp_container">
        <input
          onChange={(e) => setPassword(e.target.value)}
          placeholder="new Password"
          className="auth_inp"
          type="password"
        />

        <img
          alt="authIcon"
          loading="lazy"
          className="auth_Icon"
          src={passwordImg}
        />
      </div>
      <div className="mb-2 auth_inp_container">
        <input
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          placeholder="confirm password"
          className="auth_inp"
          type="password"
        />

        <img
          alt="authIcon"
          loading="lazy"
          className="auth_Icon"
          src={passwordImg}
        />
      </div>
      <div className="d-flex justify-content-center">
        <button onClick={handleClick} className="rounded-pill btn">
          send
        </button>
      </div>
    </div>
  );
};

export default NewPasswordForm;
