import React from "react";
import style from "./WorkoutDetails.module.css";
import { useSelector } from "react-redux";
import axios from "axios";
const Details = ({ name, instaructions, weeksNum, id }) => {
  const lang = useSelector((state) => state.langSlice.lang);
  const apiUrl = "https://fittnesstest.technomasr.com/api";
  const user = localStorage.getItem("userId")
    ? JSON.parse(localStorage.getItem("userId"))
    : null;
  const handleButtonClick = async () => {
    if (user) {
      // Make API call to get the external link based on the order ID
      const response = await axios.post(`${apiUrl}/stripe`, null, {
        headers: {
          user,
          orderId: id,
          lang,
        },
      });
      const { data } = response;
      console.log("data", data);
      // Open the external link in a new tab/window
      if (data.status !== "faild") {
        window.open(data.url, "_blank");
      } else {
        alert("try again");
      }
    } else {
      alert("you need to login");
    }
  };
  return (
    <div className="my-5 container">
      <div className="row justify-content-center align-items-center">
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="col-12 col-md-6 mb-4 mb-md-0"
        >
          <div className="d-flex flex-column flex-md-row gap-3 justify-content-center justify-content-md-start align-items-center">
            <div>
              <h2
                data-aos="fade-down"
                data-aos-delay="200"
                className="fs-3 mb-4 fw-bolder"
              >
                {name}
              </h2>
              <p
                data-aos="fade-up"
                data-aos-delay="300"
                className="text-black-50 mb-3 fs-5"
              >
                {weeksNum}
              </p>
              <p
                data-aos="fade-down"
                data-aos-delay="400"
                className="text-black-50 fs-5"
              >
                {lang === "en" ? "11 excerisces" : "11 تمرين"}
              </p>
              <button onClick={handleButtonClick} className="btn">
                join now
              </button>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-down"
          data-aos-delay="200"
          className={`col-12 col-md-6 mb-4 text-white mb-md-0 ${style.instContainer} p-3`}
        >
          <div
            data-aos="fade-up"
            data-aos-offset="100"
            className={`mb-5 text-center  ${
              lang === "en" ? "text-md-start" : "text-md-end"
            }`}
          >
            <h2
              data-aos="fade-down"
              data-aos-delay="200"
              className="fs-3 mb-4 fw-bolder text-white "
            >
              {lang === "en" ? "Details" : "التفاصيل"}
            </h2>
            <p
              data-aos="fade-down"
              data-aos-delay="300"
              className="lh text-white"
            >
              {instaructions}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
/*
   <div className="pt-3">
        <h2
          className={`fs-3 mb-3 fw-bolder text-center  ${
            lang === "en" ? "text-md-start" : "text-md-end"
          }`}
        >
          {lang === "en" ? "Excerises" : "التمارين"}
        </h2>
      </div>
      <div className={`pt-3 row justify-content-center ${style.heroConainer}`}>
        {data.weeks.map((item, index) => (
          <div
            key={index}
            className={`col-12 col-md-8 p-2 ${style.detailsContainer}`}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center gap-4">
                <p className="fw-bolder fs-4 m-0 p-0 ">{item.weekName}</p>
                <p className="text-black-50 m-0 p-0">{item.sessionsNum}</p>
              </div>
              {!showEx && !selectedWeekId && lang === "en" && (
                <p>
                  <BsArrowRightCircle
                    onClick={() => {
                      setShowEx(true);
                      setSelectedWeekId(item.id);
                    }}
                    size={30}
                    className={style.pointer}
                  />{" "}
                </p>
              )}
              {!showEx && !selectedWeekId && lang === "ar" && (
                <p>
                  <BsArrowLeftCircle
                    onClick={() => {
                      setShowEx(true);
                      setSelectedWeekId(item.id);
                    }}
                    size={30}
                    className={style.pointer}
                  />{" "}
                </p>
              )}
              {selectedWeekId === item.id && showEx && (
                <p>
                  <BsArrowDownCircle
                    size={30}
                    className={style.pointer}
                    onClick={() => {
                      setShowEx(false);
                      setSelectedWeekId(null);
                    }}
                  />{" "}
                </p>
              )}
            </div>
            {selectedWeekId === item.id ? (
              <div className={`row py-3 justify-content-center `}>
                {item.sessions.map((ex, index) => (
                  <div
                    key={index}
                    className={`p-0 h-100 col-12 col-md-10 mb-4 `}
                  >
                    <div className="d-flex align-items-center justify-content-between ">
                      <p className="fw-bolder fs-5">{ex.sessoinNum}</p>
                      <p className="text-black-50  ">{ex.exNum}</p>
                    </div>
                    <div className="text-black-50 mb-3 lh text-center fw-bold">
                      {ex.exDesc}
                    </div>
                    {ex.ex.map((item, index) => (
                      <div
                        key={index}
                        className={`d-flex align-items-center gap-5 mb-3 pb-4  `}
                      >
                        <div className={style.imgContainer}>
                          <img
                            alt="exercise/img"
                            src={item.img}
                            className={style.img}
                          />
                          <div className={style.borderLeft}></div>
                          <div className={style.borderRight}></div>
                        </div>
                        <div className="d-flex flex-column gap-3">
                          <h4 className="fw-bold">{item.title}</h4>
                          <div className="d-flex gap-3">
                            <p className="text-black-50">{item.sets}</p>
                            <p className="text-black-50">{item.reps}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
*/
