import React from "react";
import style from "./hero.module.css";
const Hero = ({ link }) => {
  return (
    <div className="position-relative">
      {link.endsWith(".mp4") ||
      link.endsWith(".mov") ||
      link.endsWith(".avi") ? (
        <video className={style.hero} loop muted autoPlay src={link}></video>
      ) : (
        <img className={style.hero} alt="hero/img" src={link} />
      )}
      <div className={style.overlay}></div>
    </div>
  );
};

export default Hero;
