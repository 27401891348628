import React from "react";
import { useParams } from "react-router-dom";
import boxing from "../assets/boxing.mp4";
import Hero from "../Components/utilites/hero/Hero";
import { transformation } from "../fakers/data";
import Content from "../Components/workout/TransformationDetails/Content/Content";
import OurCommunity from "../Components/home/OurCommunity/OurCommunity";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner";
const TransformationDetails = () => {
  const params = useParams();

  const fetchDetails = (id) => {
    return request({ url: `/transformation/${id}` });
  };
  const { isLoading, data } = useQuery(
    ["transformation-details", params.id],
    () => fetchDetails(params.id),
   
  );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Hero link={data.data.onlineCoaching_header_img} />
          <div className="py-5 my-5">
            <Content item={data.data.data} />
          </div>
        </div>
      )}
    </>
  );
};

export default TransformationDetails;
