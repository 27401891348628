import React, { useState } from "react";
import style from "./otp.module.css";
import { useMutation } from "react-query";
import { request } from "../../Components/utilites/axios.js";
import { useNavigate, useLocation } from "react-router-dom";
const OtpForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const account = location?.state?.account || "";
  window.localStorage.setItem("account", JSON.stringify(account));
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const handleOtpChange = (index, value) => {
    // Update the digit at the specified index
    const updatedOtpDigits = [...otpDigits];
    updatedOtpDigits[index] = value;
    setOtpDigits(updatedOtpDigits);
  };
  const handleLogin = (data) => {
    return request({ url: "/auth/check/code", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(handleLogin, {
    onSuccess: (data) => {
      if (data.data.status) {
        alert("done");
      }
    },
    onError: () => {
      alert("not done");
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    const code = otpDigits.join("");
    if (!code) {
      return;
    } else {
      const userData = { code, account };
      console.log("this is otp", code);
      mutate(userData);
      navigate("/new-password", { state: account });
    }
  };
  return (
    <div>
      <div className="d-flex align-items-center gap-4 justify-content-center mt-3 mb-4">
        {otpDigits.map((item, index) => (
          <input
            key={index}
            maxLength={1}
            type="text"
            value={item}
            className={style.otpInp}
            onChange={(e) => handleOtpChange(index, e.target.value)}
          />
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <button onClick={handleClick} className="rounded-pill btn">
          send
        </button>
      </div>
    </div>
  );
};

export default OtpForm;
