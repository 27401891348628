import React from "react";
import style from "./HowWork.module.css";
import Steps from "../../utilites/steps/Steps";
import MainTitle from "../../utilites/MainTitile/MainTitle";
const HowWork = ({ data }) => {
  console.log("hello from how work comp this is data", data);
  return (
    <div
      className="mainPadding"
      id="how"
      data-aos="fade-up"
      data-aos-offset="100"
    >
      <div className="container">
        <MainTitle title={data.mainSection_} desc={data.mainSectionDesc_} />

        <div className="row gap-5  justify-content-center">
          <div className="col-12 col-md-3 mb-4 mb-md-0">
            <Steps data={data?.features} start={0} end={2} />
          </div>
          <div className={` col-12 col-md-4  mb-md-0 ${style.ImgContainer}`}>
            <img
              src={data.mainSection_img}
              alt="work/img"
              className={` ${style.mainImg}`}
              data-aos="zoom-in"
            />
            <img
              className={style.subImg}
              alt="sub/img"
              src="https://user-images.githubusercontent.com/4648648/80730328-a53c3200-8b33-11ea-8807-b6df9a5d9796.png"
            />
          </div>
          <div className="col-12 pt-5 mt-5 pt-md-0 mt-md-0 col-md-3 mb-4 mb-md-0">
            <Steps data={data?.features} start={2} end={4} num={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWork;
