import React, { useState } from "react";
import user from "../../assets/user.svg";
import phoneImg from "../../assets/phone.svg";
import emailImg from "../../assets/email.svg";
import hieghtImg from "../../assets/height.png";
import weightImg from "../../assets/weight.png";
import chestImg from "../../assets/chest.png";
import passwordImg from "../../assets/password.svg";
import { useMutation } from "react-query";
import { request } from "../../Components/utilites/axios.js";
import Spinner from "../utilites/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
const RegForm = () => {
  const lang = useSelector((state) => state.langSlice.lang);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNameIcon, setShowNameIcon] = useState(true);
  const [showPhoneIcon, setShowPhoneIcon] = useState(true);
  const [showEmailIcon, setShowEmailIcon] = useState(true);
  const [showPasswordIcon, setShowPasswordIcon] = useState(true);
  const [showConfirmPasswordIcon, setShowConfirmPasswordIcon] = useState(true);
  const [date, setDate] = useState("");
  const [weight, setWeight] = useState("");
  const [showWeightIcon, setShowWeightIcon] = useState(true);
  const [showDateIcon, setShowDateIcon] = useState(true);
  const [userId, setUserId] = useState(null);
  const [height, setHeight] = useState("");
  const [showHeightIcon, setShowHeightIcon] = useState(true);
  const handleChangeName = (e) => {
    setName(e.target.value);
    if (name.trim() !== "") {
      setShowNameIcon(false);
    } else {
      setShowNameIcon(true);
    }
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    if (password.trim() !== "") {
      setShowPasswordIcon(false);
    } else {
      setShowPasswordIcon(true);
    }
  };
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (confirmPassword.trim() !== "") {
      setShowConfirmPasswordIcon(false);
    } else {
      setShowConfirmPasswordIcon(true);
    }
  };
  const handleChangePhone = (e) => {
    setPhone(e.target.value);
    if (phone.trim() !== "") {
      setShowPhoneIcon(false);
    } else {
      setShowPhoneIcon(true);
    }
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (email.trim() !== "") {
      setShowEmailIcon(false);
    } else {
      setShowEmailIcon(true);
    }
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
    if (email.trim() !== "") {
      setShowDateIcon(false);
    } else {
      setShowDateIcon(true);
    }
  };
  const handleWeightChange = (e) => {
    setWeight(e.target.value);
    if (email.trim() !== "") {
      setShowWeightIcon(false);
    } else {
      setShowWeightIcon(true);
    }
  };
  const handleHeightChange = (e) => {
    setHeight(e.target.value);
    if (email.trim() !== "") {
      setShowHeightIcon(false);
    } else {
      setShowHeightIcon(true);
    }
  };
  const navigate = useNavigate();
  const handleLogin = (data) => {
    return request({ url: "/auth/register", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(handleLogin, {
    onSuccess: (data) => {
      if (data?.data.status && lang === "en") {
        toast.success("your account created succfully");

        setUserId(data.data.data.id);
        window.localStorage.setItem(
          "userId",
          JSON.stringify(data.data.data.id)
        );
      } else {
        toast.success("تم انشاء الحساب بنجاح");
      }

      setName("");
      setPhone("");
      setEmail("");
      setPassword("");
      navigate("/");
      window.location.reload();
    },
    onError: () => {
      if (lang === "en") {
        toast.error("there is an error occured , please try again");
      } else {
        toast.error("حدث خطأ من فضلك حاول مرة اخري");
      }
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (
      name.trim() === "" ||
      password.trim() === "" ||
      phone.trim() === "" ||
      email.trim() === ""
    ) {
      return;
    } else {
      const userData = { name, password, phone, email };
      mutate(userData);
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <form>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleChangeName(e)}
              placeholder={lang === "en" ? "name" : "الاسم"}
              className="auth_inp"
              type="text"
            />

            {showNameIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={user}
              />
            )}
          </div>

          <div className="mb-2 auth_inp_container">
            <input
              placeholder={lang === "en" ? "phone number" : "رقم الهاتف"}
              onChange={(e) => handleChangePhone(e)}
              className="auth_inp"
              type="number"
            />

            {showPhoneIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={phoneImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              placeholder={lang === "en" ? "email" : "البريد الالكتروني"}
              className="auth_inp"
              type="email"
              onChange={(e) => handleChangeEmail(e)}
              value={email}
            />

            {showEmailIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={emailImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              placeholder={lang === "en" ? "password" : "كلمة المرور"}
              onChange={(e) => handleChangePassword(e)}
              className="auth_inp"
              type="password"
            />

            {showPasswordIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={passwordImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleChangeConfirmPassword(e)}
              placeholder={
                lang === "en" ? "confirm password" : "تأكيد كلمة المرور"
              }
              className="auth_inp"
              type="password"
            />

            {showConfirmPasswordIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={passwordImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleDateChange(e)}
              className="auth_inp"
              type="date"
            />
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleWeightChange(e)}
              placeholder={lang === "en" ? "Your Weight" : "الوزن"}
              className="auth_inp"
              type="number"
            />

            {showWeightIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={weightImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleHeightChange(e)}
              placeholder={lang === "en" ? "Your height" : "الطول"}
              className="auth_inp"
              type="number"
            />

            {showHeightIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={hieghtImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleHeightChange(e)}
              placeholder={lang === "en" ? "chest" : "محيط الصدر"}
              className="auth_inp"
              type="number"
            />

            {showHeightIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={chestImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleHeightChange(e)}
              placeholder={lang === "en" ? "hips" : "ارداف"}
              className="auth_inp"
              type="number"
            />

            {showHeightIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={chestImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleHeightChange(e)}
              placeholder={lang === "en" ? "arm" : "محيط الذراع"}
              className="auth_inp"
              type="number"
            />

            {showHeightIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={chestImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleHeightChange(e)}
              placeholder={lang === "en" ? "waist" : "محيط الوسط"}
              className="auth_inp"
              type="number"
            />

            {showHeightIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={chestImg}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={(e) => handleHeightChange(e)}
              placeholder={lang === "en" ? "thigh" : "محيط الفخد"}
              className="auth_inp"
              type="number"
            />

            {showHeightIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={chestImg}
              />
            )}
          </div>
          <div className="ms-3 mb-4 d-flex align-items-center gap-2">
            <input type="checkbox" id="check" />
            <label htmlFor="check" className="checkLabel">
              By continuing you accept our Privacy Policy and Term of Use
            </label>
          </div>
          <div className=" my-2 d-flex justify-content-center a;ign-items-center">
            <button onClick={handleClick} className="btn rounded-pill">
              sign up
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default RegForm;
