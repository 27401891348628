import React, { useState } from "react";
import style from "./AllWorkouts.module.css";

import ReactPaginate from "react-paginate";
import WorkoutCard from "../WorkOutCard/WorkoutCard";
const AllWorkouts = ({data}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 12;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = data.length / usersPerPage;
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div>
      <div className="row">
        {data
          .slice(pagesVisited, pagesVisited + usersPerPage)
          .map((item, index) => (
            <WorkoutCard item={item} key={index} />
          ))}
      </div>
      <div className="d-flex justify-content-center">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          breakLabel="..."
        />
      </div>
    </div>
  );
};

export default AllWorkouts;
