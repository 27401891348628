import React, { useState, useEffect } from "react";
import style from "./nav.module.css";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import SocialMedia from "../../utilites/SocialMedia/SocialMedia";
import logo2 from "../../../assets/203.png";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../../../Redux/lang.js";
import { useMutation } from "react-query";
import { request } from "../../utilites/axios.js";
import toast from "react-hot-toast";

const Navbar = ({ userId }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [whiteLinks, setWhiteLinks] = useState(true);
  const lang = useSelector((state) => state.langSlice.lang);
  const [loggedOut, setIsLoggedOut] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleShadow = () => {
      if (window.scrollY > 90) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };
    document.addEventListener("scroll", handleShadow);
  }, []);
  useEffect(() => {
    if (
      pathname === "/orders" ||
      pathname === "/login" ||
      pathname === "/reg" ||
      pathname === "/forget" ||
      pathname === "/new-password" ||
      pathname === "/otp" ||
      pathname === "/orders" ||
      pathname === "/billing" ||
      pathname === "/payment"
    ) {
      setWhiteLinks(false);
    } else {
      setWhiteLinks(true);
    }
  }, [pathname]);
  const handleLogOut = (data) => {
    return request({
      url: "/user/logout",
      method: "post",
      data,
      headers: {
        user: userId,
      },
    });
  };
  const { isLoading, mutate } = useMutation(handleLogOut, {
    onSuccess: (data) => {
      if (data.data.status) {
        toast.success(
          lang === "en"
            ? "your are logged out succesfully "
            : "تم تسجيل الخروج بنجا"
        );
        setIsLoggedOut(true);
        window.localStorage.removeItem("userId");
        window.location.reload();
      } else {
        toast.error(
          lang === "en"
            ? "there is an error occured please try again"
            : "حدث خطأ يرجي المحاولة مرة اخري"
        );
      }
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    const userdata = {};
    mutate(userdata);
  };
  return (
    <div>
      {/* larg screen view*/}
      <div className="d-none d-md-block ">
        <div
          className={`${style.navContainer} ${
            shadow ? style.setShadow : style.removeShadow
          }`}
        >
          <div className="container pt-2 d-flex justify-content-between align-items-center">
            <div className={style.logoContainer}>
              <img
                src={logo2}
                alt="logo/img"
                className={`d-flex align-items-center ${style.logo}`}
              />
            </div>
            <ul
              className={`d-flex text-white align-items-center gap-3 ${style.linksMenu}`}
            >
              <li>
                <Link
                  className={` ${
                    shadow ? style.whiteNavLinks : style.navlinks
                  } ${whiteLinks ? "text-white" : "text-black"}`}
                  to="/"
                >
                  {lang === "en" ? "Home" : "الرئيسية"}
                </Link>
              </li>

              <li>
                <Link
                  className={` ${
                    shadow ? style.whiteNavLinks : style.navlinks
                  } ${whiteLinks ? "text-white" : "text-black"}`}
                  to="/workout"
                >
                  {lang === "en" ? "Workouts" : "التدريبات"}
                </Link>
              </li>
              <li>
                <Link
                  className={` ${
                    shadow ? style.whiteNavLinks : style.navlinks
                  } ${whiteLinks ? "text-white" : "text-black"}`}
                  to="/comp"
                >
                  {lang === "en" ? "Challenges" : "التحديات"}
                </Link>
              </li>
              <li>
                <Link
                  className={` ${
                    shadow ? style.whiteNavLinks : style.navlinks
                  } ${whiteLinks ? "text-white" : "text-black"}`}
                  to="/online"
                >
                  {lang === "en" ? "online coaching" : "التدريب عبر الإنترنت"}
                </Link>
              </li>
              <li>
                <Link
                  className={` ${
                    shadow ? style.whiteNavLinks : style.navlinks
                  } ${whiteLinks ? "text-white" : "text-black"}`}
                  to="/faq"
                >
                  {lang === "en" ? "FAQ" : "الأسئلة الشائعة"}
                </Link>
              </li>
              <li>
                <a
                  className={` ${
                    shadow ? style.whiteNavLinks : style.navlinks
                  } ${whiteLinks ? "text-white" : "text-black"}`}
                  href="/#app"
                >
                  {lang === "en" ? "App" : "التطبيق"}
                </a>
              </li>

              {userId ? (
                <li>
                  <button
                    onClick={handleClick}
                    className={`logoutBtn ${
                      shadow ? style.whiteNavLinks : style.navlinks
                    } ${whiteLinks ? "text-white" : "text-black"}`}
                  >
                    {lang === "en" ? "log out" : "تسجيل الخروج"}
                  </button>
                </li>
              ) : (
                <li>
                  <Link
                    className={`${
                      shadow ? style.whiteNavLinks : style.navlinks
                    } ${whiteLinks ? "text-white" : "text-black"}`}
                    to="/login"
                  >
                    {lang === "en" ? "login" : "تسجيل الدخول"}
                  </Link>
                </li>
              )}
              {userId ? null : (
                <li>
                  <Link
                    className={`${
                      shadow ? style.whiteNavLinks : style.navlinks
                    } ${whiteLinks ? "text-white" : "text-black"}`}
                    to="/reg"
                  >
                    {lang === "en" ? "register" : "انشاء حساب"}
                  </Link>
                </li>
              )}
              <li>
                <Link
                  className={` ${
                    shadow ? style.whiteNavLinks : style.navlinks
                  } ${whiteLinks ? "text-white" : "text-black"}`}
                  to={userId ? "/orders" : "/login"}
                >
                  <BsFillCartCheckFill size={30} />
                </Link>
              </li>
              <li className="dropdown my-4 mx-0 p-0">
                <button
                  className=" langBtn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {lang === "en" ? "en" : "العربية"}
                </button>
                <ul class="dropdown-menu dropdown-menu-dark">
                  <li
                    onClick={() => {
                      dispatch(changeLang("en"));
                      window.location.reload();
                    }}
                    className="dropdown-item active"
                  >
                    {lang === "en" ? "En" : "الانجليزية"}
                  </li>
                  <li
                    onClick={() => {
                      dispatch(changeLang("ar"));
                      window.location.reload();
                    }}
                    className="dropdown-item"
                  >
                    {lang === "en" ? "ar" : "العربية"}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*mobile view*/}
      <div className="d-md-none">
        <div
          className={`${style.navContainer} ${
            shadow ? style.setShadow : style.removeShadow
          }`}
        >
          <div className="container d-flex justify-content-between align-items-center">
            <div className={style.logoContainer}>
              <img src={logo2} alt="logo/img" className={style.logo} />
            </div>
            <div>
              <GiHamburgerMenu
                onClick={() => setShowMenu(true)}
                size={30}
                className={style.icon}
              />
            </div>
          </div>
          <div
            className={`${style.menuContainer} d-flex  ${
              showMenu ? style.show : style.hide
            }`}
          >
            <div className={`pt-5`}>
              <div>
                <AiOutlineClose
                  size={20}
                  className={style.closeIcon}
                  onClick={() => setShowMenu(false)}
                />
              </div>
              <ul className="px-3">
                <li className="mt-4">
                  <Link
                    onClick={() => setShowMenu(false)}
                    className={` text-white ${style.mobLink}`}
                    to="/"
                  >
                    {lang === "en" ? "Home" : "الرئيسية"}
                  </Link>
                </li>
                <li className="my-4">
                  <Link
                    onClick={() => setShowMenu(false)}
                    className={` text-white ${style.mobLink}`}
                    to="/workout"
                  >
                    {lang === "en" ? "Workouts" : "التدريبات"}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setShowMenu(false)}
                    className={` ${
                      shadow ? style.whiteNavLinks : style.navlinks
                    } ${whiteLinks ? "text-white" : "text-black"}`}
                    to="/comp"
                  >
                    {lang === "en" ? "Challenges" : "التحديات"}
                  </Link>
                </li>
                <li className="my-4">
                  <Link
                    onClick={() => setShowMenu(false)}
                    className={`text-white ${style.mobLink}`}
                    to="/online"
                  >
                    {lang === "en" ? "online coaching" : "التدريب عبر الإنترنت"}
                  </Link>
                </li>
                <li className="my-4">
                  <Link
                    onClick={() => setShowMenu(false)}
                    className={`text-white ${style.mobLink}`}
                    to="/faq"
                  >
                    {lang === "en" ? "FAQ" : "الأسئلة الشائعة"}
                  </Link>
                </li>
                <li className="my-4">
                  <a
                    onClick={() => setShowMenu(false)}
                    className={` text-white ${style.mobLink}`}
                    href="/#app"
                  >
                    {lang === "en" ? "App" : "التطبيق"}
                  </a>
                </li>
                {!loggedOut ? (
                  <li>
                    <button
                      onClick={handleClick}
                      className={`logoutBtn ${
                        shadow ? style.whiteNavLinks : style.navlinks
                      } ${whiteLinks ? "text-white" : "text-black"}`}
                    >
                      {lang === "en" ? "log out" : "تسجيل الخروج"}
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link
                      className={`${
                        shadow ? style.whiteNavLinks : style.navlinks
                      } ${whiteLinks ? "text-white" : "text-black"}`}
                      to="/login"
                    >
                      {lang === "en" ? "login" : "تسجيل الدخول"}
                    </Link>
                  </li>
                )}
                {userId ? null : (
                  <li>
                    <Link
                      className={`${
                        shadow ? style.whiteNavLinks : style.navlinks
                      } ${whiteLinks ? "text-white" : "text-black"}`}
                      to="/reg"
                    >
                      {lang === "en" ? "register" : "انشاء حساب"}
                    </Link>
                  </li>
                )}
                <li className="my-4">
                  <Link
                    onClick={() => setShowMenu(false)}
                    className={` ${
                      shadow ? style.whiteNavLinks : style.navlinks
                    } ${whiteLinks ? "text-white" : "text-black"}`}
                    to={userId ? "/orders" : "/login"}
                  >
                    <BsFillCartCheckFill size={30} />
                  </Link>
                </li>
                <li className="dropdown my-4">
                  <button
                    className="btn langBtn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {lang === "en" ? "en" : "العربية"}
                  </button>
                  <ul class="dropdown-menu dropdown-menu-dark">
                    <li
                      onClick={() => {
                        dispatch(changeLang("en"));
                        window.location.reload();
                      }}
                      className="dropdown-item active"
                    >
                      {lang === "en" ? "En" : "الانجليزية"}
                    </li>
                    <li
                      onClick={() => {
                        dispatch(changeLang("ar"));
                        window.location.reload();
                      }}
                      className="dropdown-item"
                    >
                      {lang === "en" ? "ar" : "العربية"}
                    </li>
                  </ul>
                </li>
              </ul>

              <div className="p-2">
                <SocialMedia />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
