import React from "react";
import style from "./Steps.module.css";
const Steps = ({ data, start, end, num }) => {
  return (
    <div>
      {data?.slice(start, end).map((item, index) => (
        <div className="text-center mb-5 " key={index}>
          <div
            data-aos="fade-down"
            data-aos-delay="300"
            className={`mx-auto  mb-3 ${style.stepNum}`}
          >
            {num ? ++num : ++index}
          </div>
          <h3 data-aos="fade-up" data-aos-delay="400" className="mb-4 w-100">
            {item.title}
          </h3>
          <p
            data-aos="fade-up"
            data-aos-delay="500"
            className="text-black-50 lh"
          >
            {item.featureDescription}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Steps;
