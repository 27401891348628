import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import style from "./progress.module.css";
import activeCircule from "../../../assets/activeCircule.svg";
import circule from "../../../assets/circule.svg";
const Progress = () => {
  const { pathname } = useLocation();
  console.log("this is path", pathname);
  const [activeBilling, setActiveBilling] = useState(false);
  const [activePayment, setActivePayment] = useState(false);
  const [activeBooking, setActiveBooking] = useState(false);
  useEffect(() => {
    if (pathname === "/billing") {
      setActiveBilling(true);
      setActiveBooking(false);
      setActivePayment(false);
    }
    if (pathname === "/payment") {
      setActiveBilling(false);
      setActiveBooking(true);
      setActivePayment(false);
    } else {
      setActiveBilling(false);
      setActiveBooking(false);
      setActivePayment(true);
    }
  }, [pathname]);
  return (
    <div className="py-4">
      {activeBilling && (
        <p className={`mx-0 mt-0 p-0 mb-4 ${style.title}`}>
          billing information
        </p>
      )}
      <div className="row  justify-content-center">
        <div className="col-12 col-md-10 d-flex justify-content-between align-items-center">
          <div
            className={`${style.circuleContainer} d-flex flex-column align-items-center gap-3`}
          >
            {activeBilling ? (
              <img
                loading="lazy"
                alt="circule/ img"
                className={style.circule}
                src={activeCircule}
              />
            ) : (
              <img
                loading="lazy"
                alt="circule/ img"
                className={style.circule}
                src={circule}
              />
            )}
            <p className="m-0 p-0 info">billing information</p>
          </div>
          <div
            className={`${style.circuleContainer} d-flex flex-column align-items-center gap-3`}
          >
            {activePayment ? (
              <img
                loading="lazy"
                alt="circule/ img"
                className={style.circule}
                src={activeCircule}
              />
            ) : (
              <img
                loading="lazy"
                alt="circule/ img"
                className={style.circule}
                src={circule}
              />
            )}
            <p className="m-0 p-0 info">payment details</p>
          </div>
          <div
            className={`${style.circuleContainer} d-flex flex-column align-items-center gap-3`}
          >
            {activeBooking ? (
              <img
                loading="lazy"
                alt="circule/ img"
                className={style.circule}
                src={activeCircule}
              />
            ) : (
              <img
                loading="lazy"
                alt="circule/ img"
                className={style.circule}
                src={circule}
              />
            )}
            <p className="m-0 p-0 info">booking completed</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
