import React from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { sliderImgs } from "../../../fakers/data";
import { Autoplay, Navigation } from "swiper";
const Slider = ({ data }) => {
  const swiperOptions = {
    loop: true,
    centeredSlides: false,
    spaceBetween: 0,
    navigation: true,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
    },
    breakpoints: {
      500: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 5,
      },
    },
  };
  return (
    <div className="w-100">
      <Swiper
        modules={[Autoplay, Navigation]}
        {...swiperOptions}
        className="mySwiper w-100"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className="slider position-relative">
            <img className="slideImg" alt={item.title} src={item.image} />
            <div className="overlay">
              <p className="text-white slideTitle">{item.name_}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
