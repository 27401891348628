import React from "react";
import Hero from "../Components/home/hero/Hero";
import HowWork from "../Components/home/howWork/HowWork";
import BestProgramms from "../Components/home/BestPrograms/BestProgramms";
import OurCommunity from "../Components/home/OurCommunity/OurCommunity";
import Slider from "../Components/home/Slider/Slider.jsx";
import BmiCalc from "../Components/home/BmiCalc/BmiCalc";
import Contact from "../Components/home/contact/Contact";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const Home = () => {
  const fetchData = () => {
    return request({ url: "/settings" });
  };
  const { data, isLoading } = useQuery("home-page", fetchData);
  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Hero
            playStore={data.data.data.ourCommunity.playStore}
            appleStore={data.data.data.ourCommunity.appleStore}
            data={data.data.data.header}
          />
          <Slider data={data.data.data.slider} />
          <HowWork data={data.data.data.MainSection} />
          <BestProgramms
            revers={false}
            bg={true}
            showTitle={true}
            showBtn={false}
            img={data.data.data.workout_section_1.section1_workout.photo}
            title={data.data.data.workout_section_1.section1_workout.name_}
            mainTitle={data.data.data.workout_section_1.section1_title_}
            mainDesc={data.data.data.workout_section_1.section1_desc_}
            desc={
              data.data.data.workout_section_1.section1_workout.instructions_
            }
          />
          <BestProgramms
            bg={false}
            img={data.data.data.workout_section_2.section2_workout.photo}
            title={data.data.data.workout_section_2.section2_workout.name_}
            desc={
              data.data.data.workout_section_2.section2_workout.instructions_
            }
            revers={true}
            showTitle={false}
            showBtn={true}
          />

          <BmiCalc img={data.data.data.images.calcuationImg} />
          <OurCommunity data={data.data.data.ourCommunity} />
          <Contact img={data.data.data.ourCommunity.contactUsImg} />
        </div>
      )}
    </div>
  );
};

export default Home;
