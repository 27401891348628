import React from "react";
import style from "./info.module.css";
import user from "../../../assets/user.svg";
import phone from "../../../assets/phone.svg";
import email from "../../../assets/email.svg";
const Info = ({
  handleChangeFirstName,
  handleChangeLasttName,
  handleChangePhone,
  handleChangeEmail,
  showFirstNameIcon,
  showLastNameIcon,
  showPhoneIcon,
  showEmailIcon,
}) => {
  return (
    <div className="py-3">
      <p className={`mx-0 mt-0 p-0 mb-4 ${style.desc}`}>
        Please tick if you're making this booking for someone else
      </p>
      <div className="row gap-3 align-items-center">
        <div className="col-12 col-md-5">
          <div className="mb-2 auth_inp_container">
            <input
              onChange={handleChangeFirstName}
              placeholder="first name"
              className="auth_inp"
              type="text"
            />

            {showFirstNameIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={user}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={handleChangePhone}
              placeholder="phone number"
              className="auth_inp"
              type="number"
            />
            {showPhoneIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={phone}
              />
            )}
          </div>
        </div>
        <div className="col-12 col-md-5 ">
          <div className="mb-2 auth_inp_container">
            <input
              onChange={handleChangeLasttName}
              placeholder="last name"
              className="auth_inp"
              type="text"
            />
            {showLastNameIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={user}
              />
            )}
          </div>
          <div className="mb-2 auth_inp_container">
            <input
              onChange={handleChangeEmail}
              placeholder="email"
              className="auth_inp"
              type="email"
            />
            {showEmailIcon && (
              <img
                alt="authIcon"
                loading="lazy"
                className="auth_Icon"
                src={email}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
