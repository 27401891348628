import React from "react";
import style from "./hero.module.css";
import credit from "../../../assets/credit.png";
import ball from "../../../assets/ball.png";

const HeroCheck = () => {
  return (
    <div className={` ${style.mainContainer}`}>
      <div className="row  justify-content-center align-items-center gap-5">
        <div className="col-12 col-md-5 text-center">
          <h2
            data-aos="fade-down"
            data-aos-delay="400"
            className="fs-4 fw-bolder text-white"
          >
            Make Your life easier
          </h2>
        </div>
        <div className="position-relative col-12 col-md-6">
          <img
            data-aos="zoom-in"
            data-aos-delay="500"
            alt="credit/img"
            src={credit}
            className={style.mainImg}
          />
          <img className={style.fistBall} alt="ball/img" src={ball} />
          <img className={style.secondBall} alt="ball/img" src={ball} />
          <img className={style.thirdBall} alt="ball/img" src={ball} />
        </div>
      </div>
    </div>
  );
};

export default HeroCheck;
/*
     <div className="mt mb-5">
      <div className="container">
        <div className="row gap-5 justify-content-center align-items-center">
          
         
        </div>
      </div>
    </div>
*/
