import React from "react";
import IntroContent from "../Components/DietCalc/IntroContent/IntroContent";
import CaloreisDetails from "../Components/DietCalc/CaloriesDetails/CaloreisDetails";
import img from "../assets/work-06.jpg";
import Hero from "../Components/utilites/hero/Hero";
const DietCalc = () => {
  const data = JSON.parse(window.localStorage.getItem("userData"));
  return (
    <div>
      <div>
        <Hero link={img} />
        <div className="container pb-3 pt-5">
          <IntroContent data={data} />
          <CaloreisDetails data={data} />
        </div>
      </div>
    </div>
  );
};

export default DietCalc;
