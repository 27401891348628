import React, { useState } from "react";
import style from "./filterHeader.module.css";
import { filterHeader } from "../../../fakers/data";

const FilterHeader = () => {
  const [activeId, setActiveId] = useState(null);
  return (
    <div className="py-3 bar">
      <p className={`mx-0 mt-0 mb-5 p-0 ${style.title}`}>orders</p>
      <div className="d-flex align-items-center gap-3 gap-md-5 mb-0">
        {filterHeader.map((item, index) => (
          <p
            onClick={() => setActiveId(index)}
            className={`${style.filterLink} m-0 p-0  pointer ${
              activeId === index ? style.active : null
            }`}
          >
            {item}
          </p>
        ))}
      </div>
    </div>
  );
};

export default FilterHeader;
