import React from "react";
import FilterHeader from "../Components/orders/filterHeader/FilterHeader";
import FilterSidebar from "../Components/orders/filterSidebar/FilterSidebar.jsx";
import OrdersCards from "../Components/orders/ordersCards/OrdersCards.jsx";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const Orders = ({ lang }) => {
  const user = JSON.parse(window.localStorage.getItem("userId"));
  const fetchData = () => {
    return request({
      url: "/user/orders",
      headers: {
        user,
      },
    });
  };
  const { data, isLoading } = useQuery("orders page", fetchData);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container mt">
          {data.data.data.length ? (
            <>
              <FilterHeader />
              <FilterSidebar />
            </>
          ) : null}
          <OrdersCards user={user} lang={lang} dataa={data.data.data} />
        </div>
      )}
    </>
  );
};

export default Orders;
