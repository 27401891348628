import React, { useState } from "react";
import style from "./contact.module.css";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { request } from "../../utilites/axios";
import { useSelector } from "react-redux";
const Contact = ({ img }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  const lang = useSelector((state) => state.langSlice.lang);
  const sendMsg = (data) => {
    return request({ url: "/sendContactMessage", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(sendMsg, {
    onSuccess: (data) => {
      if (data?.data.status && lang === "en") {
        toast.success("your message sent succesfully");
        setName("");
        setEmail("");
        setContent("");
        setPhone("");
      } else {
        toast.success("تم ارسال الرسالة بنجاح");
        setName("");
        setEmail("");
        setContent("");
        setPhone("");
      }
    },
    onError: () => {
      if (lang === "en") {
        toast.error("there is an error ocuured , please try again");
      } else {
        toast.error("حدث خطأ في ارسال البياتات . من فضلك حاول مرة اخري");
      }
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (name.trim() === "" || email.trim() === "" || phone.trim() === "") {
      return;
    } else {
      const userData = { email, name, phone, content };
      mutate(userData);
      setName("");
      setEmail("");
      setContent("");
      setPhone("");
    }
  };
  return (
    <div className={style.contact}>
      <div className="container py-5">
        <h3 className="text-center mb-3 fs-3">
          {lang === "en" ? "contact us" : "تواصل معنا"}
        </h3>
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <form className={`p-3 ${style.mainContainer}`}>
              <div className="mb-3">
                <label className="label d-block mb-1" htmlFor="name">
                  {lang === "en" ? "Name" : "الاسم"}
                </label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className={style.inp}
                  value={name}
                />
              </div>
              <div className="mb-3">
                <label className="label d-block mb-1" htmlFor="email">
                  {lang === "en" ? "email" : "البريد الالكتروني"}
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className={style.inp}
                  value={email}
                />
              </div>
              <div className="mb-3">
                <label className="label d-block mb-1" htmlFor="phone">
                  {lang === "en" ? "phone" : "رقم الموبايل"}
                </label>
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  type="number"
                  className={style.inp}
                  value={phone}
                />
              </div>
              <div className="mb-3">
                <label className="label d-block mb-1" htmlFor="msg">
                  {lang === "en" ? "message" : "رسالتك"}
                </label>
                <textarea
                  onChange={(e) => setContent(e.target.value)}
                  className={style.area}
                  value={content}
                ></textarea>
              </div>
              <div className="mb-3 d-flex align-items-center justify-content-center">
                <button onClick={handleClick} className="btn">
                  {lang === "en" ? "send message" : "ارسال"}
                </button>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <img
              data-aos="zoom-in"
              data-aos-delay="400"
              className={style.mainImg}
              alt="contact/img"
              loading="lazy"
              src={img}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
