import React, { useState } from "react";
import email from "../../assets/email.svg";
import { useMutation } from "react-query";
import { request } from "../../Components/utilites/axios.js";
import { useNavigate } from "react-router-dom";
const ForgetForm = () => {
  const navigate = useNavigate();
  const [account, setAccount] = useState("");

  const handleLogin = (data) => {
    return request({ url: "/auth/forget/password", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(handleLogin, {
    onSuccess: (data) => {
      if (data?.data.status) {
        alert("done");
        console.log("this is forget page", data.data);
      }
    },
    onError: () => {
      alert("not done");
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (account.trim() === "") {
      return;
    } else {
      const userData = { account };
      mutate(userData);
      navigate("/otp", { state: userData });
    }
  };
  return (
    <form>
      <div className="mb-2 auth_inp_container">
        <input
          onChange={(e) => setAccount(e.target.value)}
          placeholder="email"
          className="auth_inp"
          type="email"
        />

        <img alt="authIcon" loading="lazy" className="auth_Icon" src={email} />
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button onClick={handleClick} className="rounded-pill btn">
          send
        </button>
      </div>
    </form>
  );
};

export default ForgetForm;
