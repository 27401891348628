import React, { useState, useEffect } from "react";
import style from "./bmi.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const BmiCalc = ({ img }) => {
  const [fn, setFn] = useState("");
  const [ln, setLn] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [email, setEmail] = useState("");
  const [activity, setActivity] = useState("");
  const [bmr, setBmr] = useState(0);
  const [calroies, setCalories] = useState(0);
  const navigate = useNavigate();
  const lang = useSelector((state) => state.langSlice.lang);

  useEffect(() => {
    if (!gender && !age && !height && !weight) {
      setBmr(0);
      setCalories(0);
    }
    if (age && height && weight && gender === "male") {
      setBmr(10 * +weight + 6.25 * +height - 5 * +age + 5);
      setCalories(+bmr * +activity);
    }
    if (age && height && weight && gender === "female") {
      setBmr(10 * +weight + 6.25 * +height - 5 * +age - 161);
      setCalories(+bmr * +activity);
    }
  }, [gender, age, height, weight, activity, bmr]);
  const handleResults = () => {
    window.localStorage.setItem(
      "userData",
      JSON.stringify({
        fn,
        ln,
        gender,
        age,
        weight,
        height,
        bmr,
        activity,
        calroies,
      })
    );
    navigate("/calc");
  };
  return (
    <div className={`my-5 ${style.mainDiv}`}>
      <img loading="lazy" alt="calc/img" src={img} className={style.mainImg} />
      <div className={style.overlay}>
        <div className="container d-flex flex-column align-items-center">
          <h3
            className={`text-white mb-5 pt-4 fw-bolder text-uppercase ${style.title}`}
          >
            {lang === "en" ? "Macro Calculation" : "معدل الحرق"}
          </h3>
          <form className={`p-4  ${style.mainContainer} rounded`}>
            <div className="row mb-2">
              <div className="col-12 col-md-6 mb-2 mb-md-0">
                <label className="d-block mb-1 fw-bold label" htmlFor="fs">
                  {lang === "en" ? "first name" : "الاسم الأول"}
                </label>
                <input
                  onChange={(e) => setFn(e.target.value)}
                  type="text"
                  id="fn"
                  className={style.inp}
                />
              </div>
              <div className="col-12 col-md-6 mb-3 mb-md-0">
                <label htmlFor="ln" className="d-block mb-1 fw-bold label">
                  {lang === "en" ? "last name" : "الاسم الاخير"}
                </label>
                <input
                  onChange={(e) => setLn(e.target.value)}
                  type="text"
                  id="ln"
                  className={style.inp}
                />
              </div>
            </div>
            <div className="mb-2">
              <label className="d-block mb-1 fw-bold label" htmlFor="mail">
                {lang === "en" ? "email" : "البريد الالكتروني"}
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="mail"
                className={style.inp}
              />
            </div>
            <div className="mb-2">
              <label className="d-block mb-1 fw-bold label" htmlFor="gender">
                {lang === "en" ? "Gender" : "النوع"}
              </label>
              <select
                onChange={(e) => setGender(e.target.value)}
                id="gender"
                className={style.inp}
              >
                <option>
                  {lang === "en" ? "choose your gender" : "اختار نوعك"}
                </option>
                <option value="male">{lang === "en" ? "Male" : "ذكر"}</option>
                <option value="female">
                  {lang === "en" ? "Femal" : "انثي"}
                </option>
              </select>
            </div>
            <div className="mb-2">
              <label className="d-block mb-1 fw-bold label" htmlFor="age">
                {lang === "en" ? "Age" : "العمر"}
              </label>
              <input
                onChange={(e) => setAge(e.target.value)}
                type="number"
                id="age"
                className={style.inp}
              />
            </div>
            <div className="row justify-content-center mb-2">
              <div className="col-12 col-md-6 mb-4 mb-md-0">
                <label htmlFor="height" className="d-block mb-1 fw-bold label">
                  {lang === "en" ? "height" : "الطول"}
                </label>
                <input
                  onChange={(e) => setHeight(e.target.value)}
                  type="number"
                  id="height"
                  className={style.inp}
                />
              </div>
              <div className="col-12 col-md-6 mb-4 mb-md-0">
                <label htmlFor="weight" className="d-block mb-1 fw-bold label">
                  {lang === "en" ? "weight" : "الوزن"}
                </label>
                <input
                  onChange={(e) => setWeight(e.target.value)}
                  type="number"
                  id="weight"
                  className={style.inp}
                />
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="activity" className="d-block mb-1 fw-bold label">
                {lang === "en" ? "activity" : "معدل النشاط"}
              </label>
              <select
                onChange={(e) => setActivity(e.target.value)}
                className={style.inp}
                id="activity"
              >
                <option value="">
                  {lang === "en" ? "Choose your activity" : "اختر معدل نشاطك"}
                </option>
                <option value="1.1">sedentary (office job)</option>
                <option value="1.2">light exercise (1 - 2 days / week )</option>
                <option value="1.5">
                  moderate exercise (3 - 5 days / week )
                </option>
                <option value="1.7">heavy exercise (6 - 7 days / week )</option>
                <option value="2">athelete (2x per day )</option>
              </select>
            </div>
            <button
              onClick={handleResults}
              className="btn text-white mx-auto d-flex justify-content-center"
            >
              {lang === "en" ? "Show Results" : "شاهد النتائج"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BmiCalc;
/*
  <MainTitle title="BMR Calculation" desc="calculate your calroies" />
        <div className={`py-5 row justify-content-center `}>
          <form className={`p-4 col-12 col-md-8 ${style.mainContainer}`}>
           
            <div className={`${style.genderContainer} py-5`}>
              <div className="row jusify-content-center">
                <div className="col-12 col-md-4 fw-bolder fs-3 mb-3 mb-md-0">
                  Gender
                </div>
                <div className="col-12 col-md-8">
                  <div className="row justify-content-center gap-4">
                    <div className="col-12 col-md-5">
                      <input
                        type="radio"
                        id="male"
                        value="male"
                        className="mx-2"
                        name="gender"
                      
                      />
                      <label htmlFor="male">Male</label>
                    </div>
                    <div className="col-12 col-md-5">
                      <input
                        type="radio"
                        id="female"
                        value="female"
                        className="mx-2"
                        name="gender"
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <label htmlFor="female">Female</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-5 row justify-content-center">
              <label
                htmlFor="age"
                className="col-12 col-md-4 mb-4 mb-md-0 fw-bolder fs-3"
              >
                Age
              </label>
              <div className="col-12 col-md-8 mb-4 mb-md-0">
                
              </div>
            </div>
            <div className="py-5 row justify-content-center">
              <label
                htmlFor="height"
                className="col-12 col-md-4 mb-4 mb-md-0 fw-bolder fs-3"
              >
                height
              </label>
              <div className="col-12 col-md-8 mb-4 mb-md-0">
               
              </div>
            </div>
            <div className="py-5 row justify-content-center">
              <label
                htmlFor="weight"
                className="col-12 col-md-4 mb-4 mb-md-0 fw-bolder fs-3"
              >
                weight
              </label>
              <div className="col-12 col-md-8 mb-4 mb-md-0">
                
              </div>
            </div>
            <div className="py-5 row justify-content-center">
              <label
                htmlFor="activity"
                className="col-12 col-md-4 mb-4 mb-md-0 fw-bolder fs-3"
              >
                activity
              </label>
              <div className="col-12 col-md-8 mb-4 mb-md-0">
                
              </div>
            </div>
            
          </form>
        </div>
*/
/*
   <div className={`${style.nameContainer} pb-5`}>
              <div className="row jusify-content-center">
                <div className="col-12 col-md-4 fw-bolder fs-3 mb-3 mb-md-0">
                  Name
                </div>
                <div className="col-12 col-md-8">
                  <div className="row justify-content-center gap-4">
                    <div className="col-12 col-md-5">
                      <label htmlFor="fn" className="d-block">
                        first name
                      </label>
                      <input
                        onChange={(e) => setFn(e.target.value)}
                        type="text"
                        id="fn"
                        className={style.inp}
                      />
                    </div>
                    <div className="col-12 col-md-5">
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
*/
