import React from 'react'
import { Helmet } from 'react-helmet'
const Meta = () => {
  return (
    <Helmet>
      <title>Fitness App</title>
    </Helmet>
  );
}

export default Meta
