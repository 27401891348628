import React from "react";
import success from "../../assets/cancel-01.png";
import { Link } from "react-router-dom";
import style from "./cancel.module.css";
const CancellSmile = () => {
  return (
    <div>
      <div className={style.imgContainer}>
        <img
          alt="success/img"
          loading="lazy"
          src={success}
          className={style.mainImg}
        />
      </div>
      <div className="container pt-5">
        <p className="mx-0 p-0 mb-2 text-center fw-bolder fs-2">OOPPPS !!!</p>
        <p className="mx-0 p-0 mb-2 text-center fw-bolder fs-5 text-black-50">
          tehre is an error occured , please try again
        </p>
        <div className="pb-4 d-flex justify-content-center align-items-center flex-wrap gap-3">
          <Link to="/workouts">continue shopping</Link>
          <Link to="/">go to home</Link>
        </div>
      </div>
    </div>
  );
};

export default CancellSmile;
