import React from "react";
import { useParams } from "react-router-dom";
import { workouts } from "../fakers/data";
import Hero from "../Components/utilites/hero/Hero";
import MainTitle from "../Components/utilites/MainTitile/MainTitle";
import Details from "../Components/workoutDetails/Details/Details";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const WorkoutDetails = () => {
  const params = useParams();
  const fetchDetails = (id) => {
    return request({ url: `/workouts/${id}` });
  };
  const { isLoading, data } = useQuery(["workout-details", params.id], () =>
    fetchDetails(params.id)
  );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Hero link={data.data.workout_header_img} />
          <div className="container">
            <div className="py-5">
              <Details
                id={params.id}
                name={data.data.data.name_}
                weeksNum={data.data.data.weeks_no}
                instaructions={data.data.data.instructions_}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkoutDetails;
