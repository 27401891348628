import React, { useState } from "react";
import style from "./form.module.css";
import { useSelector } from "react-redux";
const CheckForm = () => {
  const lang = useSelector((state) => state.langSlice.lang);
  const [cardHolder, setCardHolder] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiry, setExpiry] = useState("");
  const [packages, setPackage] = useState("");
  const [cardNum, setCardNum] = useState("");
  const handleCVV = (event) => {
    // restrict input to 3 numbers only
    if (event.target.value.length >= 3) {
      event.preventDefault();
    }
  };

  const handleExpiryChange = (event) => {
    const value = event.target.value;
    let formattedValue = value.replace(/\D/g, "").slice(0, 4); // Remove non-digits and limit to 4 characters
    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
        2
      )}`; // Add a slash after the first two digits
    }
    setExpiry(formattedValue);
  };
  const validateCardNum = (e) => {
    if (e.target.value.length >= 14) {
      e.preventDefault();
    }
  };
  const handleSubmit = async () => {
    await fetch(`api`, {
      method: "POST",
      headers: {
        lang,
      },
      body: JSON.stringify({
        cardHolder,
        cvv,
        cardNum,
        expiry,
        packages,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  };
  return (
    <div className="row justify-content-center my-5">
      <div
        data-aos="zoom-in"
        data-aos-delay="500"
        className={`py-5 container col-10 ${style.formContainer}`}
      >
        <div className="px-5">
          <h3
            data-aos="fade-down"
            data-aos-delay="300"
            className="fs-4 fw-bolder mb-5"
          >
            {lang === "en" ? "Check out" : "الدفع"}
          </h3>
          <form>
            <div className="row justify-content-center mb-4">
              <div
                data-aos="fade-down"
                data-aos-delay="400"
                className="col-12 col-md-6 mb-4 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="ch"
                >
                  {lang === "en" ? "Card Holder" : "حامل البطاقة"}
                </label>
                <input
                  onChange={(e) => setCardHolder(e.target.value)}
                  required
                  id="ch"
                  placeholder={`${
                    lang === "en" ? "Card Holder" : "حامل البطاقة"
                  }`}
                  className={style.inp}
                />
              </div>
              <div
                data-aos="fade-up"
                data-aos-delay="500"
                className="col-12 col-md-6 mb-4 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="cvv"
                >
                  {lang === "en" ? "CVV" : "رقم البطاقة"}
                </label>
                <input
                  required
                  type="number"
                  minLength="3"
                  maxLength="3"
                  id="cvv"
                  onChange={(e) => setCvv(e.target.value)}
                  onKeyPress={handleCVV}
                  placeholder={`${lang === "en" ? "CVV" : "CVV"}`}
                  className={style.inp}
                />
              </div>
            </div>
            <div className="row justify-content-center mb-4">
              <div
                data-aos="fade-down"
                data-aos-delay="600"
                className="col-12 col-md-6 mb-4 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="num"
                >
                  {lang === "en" ? "Card Number" : "رقم البطاقة"}
                </label>
                <input
                  onChange={(e) => setCardNum(e.target.value)}
                  required
                  onKeyPress={validateCardNum}
                  placeholder={`${
                    lang === "en" ? "Card Number" : "رقم البطاقة"
                  }`}
                  type="number"
                  id="num"
                  className={style.inp}
                />
              </div>
              <div
                data-aos="fade-up"
                data-aos-delay="700"
                className="col-12 col-md-6 mb-4 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="exp"
                >
                  {lang === "en" ? "Expired Date" : "تاريخ انتهاء البطاقة"}
                </label>
                <input
                  required
                  value={expiry}
                  onChange={handleExpiryChange}
                  type="text"
                  id="exp"
                  placeholder="MM/YY"
                  className={style.inp}
                />
              </div>
            </div>
            <div className="row justify-content-center mb-4">
              <div
                data-aos="fade-down"
                data-aos-delay="600"
                className="col-12 col-md-6 mb-4 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="package"
                >
                  {lang === "en" ? "Packages" : "الاشتراكات"}
                </label>
                <select
                  required
                  id="package"
                  onChange={(e) => setPackage(e.target.value)}
                  className={style.inp}
                >
                  <option value="">Choose Your Package</option>
                  <option value="100">Primary</option>
                  <option value="200">Selver</option>
                  <option value="300">Gold</option>
                </select>
              </div>
              <div
                data-aos="fade-up"
                data-aos-delay="700"
                className="col-12 col-md-6 mb-4 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="total"
                >
                  {lang === "en" ? "Total Price" : "السعر"}
                </label>
                <input
                  disabled={true}
                  value={packages}
                  type="text"
                  id="total"
                  className={style.inp}
                />
              </div>
            </div>
            <button className="d-flex justify-content-center btn mx-auto text-center fw-bold my-5 text-white">
              {lang === "en" ? "Check out" : "ادفع"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckForm;
