import React, { useState } from "react";
import style from "./faq.module.css";
import {
  AiOutlineArrowDown,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { useSelector } from "react-redux";
const FaqDetails = ({ data }) => {
  const lang = useSelector((state) => state.langSlice.lang);
  const [activeId, setActiveId] = useState(null);
return (
    <div className={`col-10  ${style.faqContainer}`}>
      <div className="p-3">
        {data.map((item, index) => (
          <div key={index}>
            <div className="mb-4 d-flex justify-content-between align-items-center">
              <h4>{item.question_}</h4>
              {(activeId === null || activeId !== index) && lang === "en" ? (
                <AiOutlineArrowRight
                  onClick={() => setActiveId(index)}
                  className={style.icon}
                  size={30}
                />
              ) : (activeId === null || activeId !== index) && lang === "ar" ? (
                <AiOutlineArrowLeft
                  onClick={() => setActiveId(index)}
                  className={style.icon}
                  size={30}
                />
              ) : (
                <AiOutlineArrowDown
                  onClick={() => setActiveId(null)}
                  className={style.icon}
                  size={30}
                />
              )}
            </div>
            {activeId === index ? (
              <div className="mt-4 d-flex flex-column gap-2 align-items-center">
                {item.answer ? (
                  <p
                    className="m-0 p-0"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                ) : null}
                {item.answer_photo ? (
                  <img
                    loading="lazy"
                    alt="faq/img"
                    src={item.answer_photo}
                    className={style.faqImg}
                  />
                ) : null}
                {item.answer_video_link ? (
                  <video className={style.video} controls>
                    <source src={item.answer_video_link} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : null}
              </div>
            ) : null}
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqDetails;
