import React from "react";
import video from "../assets/pexels-tima-miroshnichenko-5319099-3840x2160-25fps.mp4";
import Hero from "../Components/utilites/hero/Hero";
import MainTitle from "../Components/utilites/MainTitile/MainTitle";
import { challenges } from "../fakers/data";
import CompetationsCard from "../Components/competaions/CompetationsCard";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const Competations = () => {
  const fetchData = () => {
    return request({ url: "/competitions" });
  };
  const { data, isLoading } = useQuery("challenges page", fetchData);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Hero link={data.data.challenges_header.challenges_header_img} />
          <div className="container py-5">
            <MainTitle
              title={data.data.challenges_header.challenges_header_title}
              desc={data.data.challenges_header.challenges_header_des}
            />
            <div className="row gap-3 justify-content-center">
              {data.data.data.map((item, index) => (
                <CompetationsCard data={item} key={index} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Competations;
