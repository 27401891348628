import React from "react";
import TransformationCard from "./card/TransformationCard";

const AllTransformation = ({ data }) => {
  return (
    <>
      <div className="row">
        {data.map((item, index) => (
          <TransformationCard item={item} index={index} />
        ))}
      </div>
    </>
  );
};

export default AllTransformation;
