import React, { useState, useEffect, useRef } from "react";
import style from "./filterSidebar.module.css";
import filterIcon from "../../../assets/filter.svg";
const FilterSidebar = () => {
  const [showFilter, setShowFilter] = useState(false);
  const sidebarRef = useRef();
  useEffect(() => {
    const handleClickOutSide = (e) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setShowFilter(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  });
  return (
    <div className="py-3">
      <div className="d-flex align-items-center gap-2 ">
        <p className={`filterBox m-0 p-0 ${style.title}`}>filter</p>
        <img
          onClick={() => setShowFilter(true)}
          loading="lazy"
          alt="filter/icon"
          src={filterIcon}
          className={style.filterIcon}
        />
      </div>
      <div
        className={`${style.overlay} ${
          showFilter ? style.showOverlay : style.hideOverlay
        }`}
      ></div>
      <div
        ref={sidebarRef}
        className={`p-4 ${style.sidebar} ${
          showFilter ? style.show : style.hide
        }`}
      >
        <p className={`mx-0 mb-5 p-0 ${style.filterTitle}`}>filter</p>
        <input
          type="number"
          className={`mb-3 ${style.orderInp}`}
          placeholder="order number"
        />
        <select className={style.orderInp}>
          <option>payment method</option>
          <option>vise</option>
          <option>cash</option>
        </select>
        <div
          className={`${style.btnContainer} d-flex align-items-center gap-4`}
        >
          <button
            className={`rounded-pill ${style.btn} ${style.cancelBtn}`}
            onClick={() => setShowFilter(false)}
          >
            cancel
          </button>
          <button className={`rounded-pill ${style.btn} ${style.applayBtn}`}>
            applay
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
