import React from "react";
import style from "./compDetails.module.css";
import { useSelector } from "react-redux";
const CompDetails = ({ data }) => {
  console.log("hello from competation details", data);
  const lang = useSelector((state) => state.langSlice.lang);
  return (
    <div>
      <p className="text-center fw-bold p-0 mb-3">{data.name_}</p>
      <div className={style.imgContainer}>
        <img
          alt="challenge/img"
          src={data.photo}
          loading="lazy"
          className={style.mainImg}
        />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-center gap-5 align-items-center">
        <p className="m-0 p-0 fw-bold fs-4">
          {data.workout.workoutsWeeks[0].week_no}
        </p>
        <div
          data-aos="fade-down"
          data-aos-delay="200"
          className={`${style.instContainer} p-3`}
        >
          <div
            data-aos="fade-up"
            data-aos-offset="100"
            className={`mb-5 text-center  ${
              lang === "en" ? "text-md-start" : "text-md-end"
            }`}
          >
            <h2
              data-aos="fade-down"
              data-aos-delay="200"
              className="fs-3 mb-4 fw-bolder text-white "
            >
              {lang === "en" ? "Details" : "التفاصيل"}
            </h2>
            <p
              data-aos="fade-down"
              data-aos-delay="300"
              className="lh text-white"
            >
              {data.workout.instructions_}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompDetails;
