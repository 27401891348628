import React from "react";
import style from "./workoutCard.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const WorkoutCard = ({ item, index }) => {
  const lang = useSelector((state) => state.langSlice.lang);
  return (
    <div
      key={index}
      data-aos="zoom-in"
      data-aos-offset="50"
      data-aos-delay={item.delay}
      className={`col-12 col-md-6 mb-5 pb-2`}
    >
      <div className={style.div}>
        <div className={`mb-4 ${style.imgContainer}`}>
          <img alt="workout/img" src={item.photo} className={style.mainImg} />
          <div className={style.borderLeft}></div>
          <div className={style.borderRight}></div>
        </div>
        <Link
          className={`text-center ${style.mainTitle} mb-5`}
          to={`/workout/${item.id}`}
        >
          <h4>{item.name_}</h4>
        </Link>

        <button
          className={`mx-auto text-center d-flex justify-content-center align-items-center my-4 ${style.btn}`}
        >
          <Link className={style.linkText} to={`/workout/${item.id}`}>
            {lang === "en" ? "learn more" : "معرفة المزيد"}
          </Link>
        </button>
      </div>
    </div>
  );
};

export default WorkoutCard;
