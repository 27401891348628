import React from "react";
import { challenges } from "../fakers/data";
import { useParams } from "react-router-dom";
import video from "../assets/pexels-tima-miroshnichenko-5319099-3840x2160-25fps.mp4";
import Hero from "../Components/utilites/hero/Hero";
import CompDetails from "../Components/competationDetails/CompDetails";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const Competation = () => {
  const params = useParams();
  const fetchDetails = (id) => {
    return request({ url: `/competitions/${id}` });
  };
  const { isLoading, data } = useQuery(
    ["competitions-details", params.id],
    () => fetchDetails(params.id),
   
  );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Hero link={data.data.challenges_header_img} />
          <div className="container py-5">
            <CompDetails data={data.data.data} />
          </div>
        </div>
      )}
    </>
  );
};

export default Competation;
