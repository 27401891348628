import React from "react";
import style from "./card.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const CompetationsCard = ({ data }) => {
  const lang = useSelector((state) => state.langSlice.lang);
  return (
    <div className={`p-0 col-12 col-md-5 col-lg-3 ${style.mainContainer}`}>
      <div className="w-100 h-100 d-flex align-items-center gap-2">
        <div className={style.imgContainer}>
          <img
            alt="challenge/img"
            src={data.photo}
            loading="lazy"
            className={style.mainImg}
          />
        </div>
        <div
          className={`d-flex flex-column align-items-center gap-3 ${style.textContent} `}
        >
          <p className="fw-bold m-0 p-0 text-center">
            {data.name_.substr(0, 20)}
          </p>
          <button
            className={`mx-auto text-center d-flex justify-content-center align-items-center my-4 ${style.btn}`}
          >
            <Link className={style.linkText} to={`/comp/${data.id}`}>
              {lang === "en" ? "learn more" : "معرفة المزيد"}
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompetationsCard;
