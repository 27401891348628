import React from "react";
import style from "./hero.module.css";
import { BsGoogle } from "react-icons/bs";
import { AiFillApple } from "react-icons/ai";
import { useSelector } from "react-redux";
const Hero = ({ data, playStore, appleStore }) => {
  const lang = useSelector((state) => state.langSlice.lang);

  return (
    <div className={`pt-0 ${style.mainContainer}`}>
      <img
        alt="main/img"
        loading="lazy"
        className={style.mainImg}
        src={data.header_img}
      />
      <div className={style.overlay}>
        <div className={`px-5 ${style.textContent}`}>
          <p
            data-aos="fade-down"
            data-aos-delay="200"
            className="fw-bold text-white text-uppercase fs-3 mb-2"
          >
            <div dangerouslySetInnerHTML={{ __html: data.site_name_ }} />
          </p>

          <p
            data-aos="fade-left"
            data-aos-delay="400"
            className={`fw-bold  text-uppercase fs-3 mb-2 ${style.name}`}
          >
            <div dangerouslySetInnerHTML={{ __html: data.siteDescription_ }} />
          </p>

          <div
            data-aos="fade-up"
            data-aos-delay="500"
            className="d-flex flex-column flex-md-row  align-items-center gap-3"
          >
            <a
              className={style.box}
              target="_blank"
              href={playStore}
              rel="noreferrer"
            >
              <BsGoogle size={20} />{" "}
              <span>{lang === "en" ? "play Store" : "متجر play"}</span>
            </a>
            <a
              className={style.box}
              target="_blank"
              href={appleStore}
              rel="noreferrer"
            >
              <AiFillApple size={20} />{" "}
              <span>{lang === "en" ? "  App Storee" : "متجر app"}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
