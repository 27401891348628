import React from "react";
import { useSelector } from "react-redux";
const MainTitle = ({ title, desc, arabicTitle }) => {
  const lang = useSelector((state) => state.langSlice.lang);
  return (
    <div className="text-center">
      <h4
        className="text-center mb-3 text-uppercase"
        data-aos="fade-down"
        data-aos-delay="150"
      >
        {lang === "en" ? title : arabicTitle}
      </h4>
      <p
        data-aos="fade-up"
        data-aos-delay="250"
        className="text-black-50 lh w-75 text-center mx-auto"
      >
        {desc}
      </p>
    </div>
  );
};

export default MainTitle;
