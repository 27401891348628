import React from "react";
import MainTitle from "../Components/utilites/MainTitile/MainTitle";
import FaqDetails from "../Components/Faq/FaqDetails";
import faq from "../assets/pexels-photo-2789781.jpeg";
import Hero from "../Components/utilites/hero/Hero";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const Faq = () => {
  const fetchData = () => {
    return request({ url: "/faqs" });
  };
  const { data, isLoading } = useQuery("faq page", fetchData);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Hero link={data.data.FAQImg} />
          <div className="container">
            <div className="py-5">
              <MainTitle
                title="common question"
                arabicTitle="الاسئلة الشائعة"
              />
              <div className="row py-5 justify-content-center">
                <FaqDetails data={data.data.data} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Faq;
