import React from "react";
import style from "./bookingRules.module.css";
const BookingRules = () => {
  return (
    <div className="py-5">
      <div className={`p-3 ${style.mainContainer}`}>
        <p className="m-0 p-0">
          By completing this booking, you agree to the{" "}
          <a href="/">Booking Conditions</a> ,
          <a href="/"> Terms and Conditions</a> , and{" "}
          <a href="/">Privacy Policy.</a>
        </p>
      </div>
    </div>
  );
};

export default BookingRules;
