import React from "react";
import logo from "../../../assets/203.png";
import style from "./footer.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SocialMedia from "../../utilites/SocialMedia/SocialMedia";
import { useQuery } from "react-query";
import { request } from "../../utilites/axios";
import Spinner from "../../utilites/Spinner/Spinner";

const Footer = () => {
  const lang = useSelector((state) => state.langSlice.lang);
  console.log("hello from footer", lang);
  const fetchData = () => {
    return request({ url: "/settings" });
  };
  const { data, isLoading } = useQuery("social-media", fetchData, {
    cacheTime: 180000000,
    staleTime: 180000000,
  });
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={`${style.mainFooter}`}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="mb-4 text-white ">
                {lang === "en"
                  ? "All Copy right reserved to"
                  : "جميع الحقوق محفوظة ل"}
                &copy; <span className={style.fitness}>fitness</span>
              </p>
              <SocialMedia data={data.data.data.social} />
            </div>
          </div>
          <div className={style.powerContainer}>
            <div className="d-flex justify-content-center align-items-center">
              <p className={`text-white pt-1`}>
                {lang === "en" ? "Powerd By" : "تم التنفيذ بواسطة"}{" "}
                <a
                  rel="noreferrer"
                  className={style.links}
                  target="_blank"
                  href="https://technomasr.com/"
                >
                  Technomasr
                </a>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
/*
    
*/
