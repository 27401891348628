import React from "react";
import style from "./content.module.css";
import { useSelector } from "react-redux";
const Content = ({ item }) => {
  const lang = useSelector((state) => state.langSlice.lang);
  return (
    <div className="">
      <h1 className={`fw-bolder  mb-3 text-center ${style.name}`}>
        {item.user_name}
      </h1>
      <div className="container pt-5">
        <div
          data-aos="fade-up"
          data-aos-offset="100"
          className="row justify-content-center"
        >
          <div
            data-aos="fade-down"
            data-aos-delay="300"
            className={`${
              lang === "en"
                ? "col-12 col-md-6 mb-4 mb-md-0 text-center text-md-start"
                : "col-12 col-md-6 mb-4 mb-md-0 text-center text-md-end"
            }`}
          >
            <div className="text-uppercase fs-5 mb-4">
              <span className="fw-bolder mx-3 d-inline-block text-black-50 ">
                {lang === "en" ? "before :" : "قبل : "}
              </span>
              {item.before_weight}
            </div>
            <div className="text-uppercase fs-5 mb-4">
              <span className="fw-bolder mx-3 d-inline-block text-black-50 ">
                {lang === "en" ? "after :" : "بعد : "}
              </span>
              {item.after_weight}
            </div>
            <div className="text-uppercase fs-5 mb-4">
              <span className="fw-bolder mx-3 d-inline-block text-black-50 ">
                {lang === "en" ? "timeframe :" : "المدة الزمنية : "}
              </span>
              {item.time_frame}
            </div>
            <div className="text-uppercase fs-5 mb-4">
              <span className="fw-bolder mx-3 d-inline-block text-black-50 ">
                {lang === "en" ? "location :" : "المكان: "}
              </span>
              {item.location}
            </div>
            <p className="lh text-black-50">{item.details}</p>
          </div>
          <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center">
            <img
              data-aos="zoom in"
              data-aos-delay="400"
              className={`img-fluid ${style.mainImg}`}
              alt={item.user_name}
              src={item.photo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
