import React, { useState } from "react";
import Hero from "../Components/utilites/hero/Hero";
import MainTitle from "../Components/utilites/MainTitile/MainTitle";
import OnlineForm from "../Components/onlineCoaching/OnlineForm/OnlineForm";
import AllTransformation from "../Components/onlineCoaching/transformation/AllTransformation";
import { useQuery } from "react-query";
import { request } from "../Components/utilites/axios";
import Spinner from "../Components/utilites/Spinner/Spinner.jsx";
const OnlineCoaching = () => {
  const [onlinePlans, setOnlinePlans] = useState([]);
  const fetchData = () => {
    return request({ url: "/transformation" });
  };
  const { data, isLoading } = useQuery("transformation", fetchData);
  const fetchPlans = () => {
    return request({ url: "/plans" });
  };
  const { data: plans, isLoading: loadingPlans } = useQuery(
    "plans",
    fetchPlans,
    {
      onSuccess: (data) => {
        const newData = data.data.data.filter(
          (item) => item.type === "onlineCoaching"
        );
        setOnlinePlans(newData);
      },
    }
  );
  return (
    <>
      {isLoading || loadingPlans ? (
        <Spinner />
      ) : (
        <div>
          <Hero
            link={data.data.onlineCoaching_header.onlineCoaching_header_img}
          />
          <div className="container py-5">
            <MainTitle
              title={
                data.data.onlineCoaching_header.onlineCoaching_header_title
              }
              desc={data.data.onlineCoaching_header.onlineCoaching_header_des}
            />
            <OnlineForm data={onlinePlans} />
            <div className="mt-5">
              <AllTransformation data={data.data.data} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnlineCoaching;
