import React from "react";
import Progress from "../Components/utilites/progress/Progress";
import BookingSummary from "../Components/utilites/bookingSummary/BookingSummary";
import BookingRules from "../Components/utilites/bookingRules/BookingRules";
import PaymentInfo from "../Components/payments/paymentInfo/PaymentInfo";
import PaymentForm from "../Components/payments/paymentForm/PaymentForm";

const Payment = () => {
  const data = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="container mt">
      <p className="mx-0 mt-5  title">checkout</p>
      <Progress />
      <div className="row">
        <div className="col-12 col-md-8 mb-3 mb-md-0">
          <PaymentInfo
            firstName={data.firstName}
            lastName={data.lastName}
            email={data.email}
            phone={data.phone}
          />
          <BookingRules />
          <PaymentForm />
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <BookingSummary isConfirm={false} />
        </div>
      </div>
    </div>
  );
};

export default Payment;
