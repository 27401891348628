import React, { useState } from "react";
import style from "./bookingSummary.module.css";
import { useNavigate } from "react-router-dom";
import BookingModal from "../bookingModal/BookingModal";
const BookingSummary = ({
  isConfirm,
  firstName,
  lastName,
  emailValue,
  phoneValue,
}) => {
  const subTotal = 8.5;
  const taxes = 1.5;
  const discount = 1.25;
  const netPrice = subTotal + taxes - discount;
  const navigate = useNavigate();
  const handleClick = () => {
    if (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      phoneValue.trim() !== "" &&
      emailValue.trim() !== ""
    ) {
      const user = {
        firstName,
        lastName,
        phone: phoneValue,
        email: emailValue,
      };
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/payment");
    } else {
      alert("first name , last name , phone and email are required fields");
    }
  };
  const [success, setSucess] = useState(false);
  const handleSuccess = () => {
    setSucess(true);
  };
  return (
    <div className={`p-2 ${style.mainContainer}`}>
      <p className={`mx-0 mt-0 mb-4 p-0 ${style.title}`}>booking Summary</p>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className={`m-0 p-0 ${style.question}`}>sub total</p>
        <p className={`m-0 p-0 ${style.answer}`}>{subTotal}$</p>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className={`m-0 p-0 ${style.question}`}>Taxes</p>
        <p className={`m-0 p-0 ${style.answer}`}>{taxes}$</p>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className={`m-0 p-0 ${style.discount}`}>discount</p>
        <p className={`m-0 p-0 ${style.discount}`}>{discount} $</p>
      </div>
      <hr className="text-black-50" />
      <div className="pb-4 d-flex flex-column align-items-center gap-1justify-content-center">
        <p className={`m-0 p-0 ${style.total}`}>total pay</p>
        <p className={`${style.price} m-0 p-0`}>{netPrice}$</p>
      </div>
      {isConfirm && (
        <button onClick={handleClick} className={style.btn}>
          confirm
        </button>
      )}
      {!isConfirm && (
        <button onClick={handleSuccess} className={style.btn}>
          {netPrice} $
        </button>
      )}
      <BookingModal success={success} setSucess={setSucess} />
    </div>
  );
};

export default BookingSummary;
