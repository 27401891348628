import React, { useRef, useEffect } from "react";
import style from "./bookingModal.module.css";
const BookingModal = ({ success, setSucess }) => {
  const modalRef = useRef();
  useEffect(() => {
    const handleShowModal = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setSucess(false);
      }
    };
    document.addEventListener("mousedown", handleShowModal);
    return () => {
      document.removeEventListener("mousedown", handleShowModal);
    };
  }, [setSucess]);
  return (
    <div
      className={`${style.overlay} ${
        success ? style.showOverlay : style.hideOverlay
      }`}
    >
      <div
        ref={modalRef}
        className={`${style.modalContainer} ${
          success ? style.showModal : style.hideModal
        }`}
      >
        <div>
          <p className={`m-0 p-0 ${style.title}`}>Booking Successful</p>
          <p className={`m-0 p-0 ${style.desc}`}>
            If you want to manage your booking and know more informations you
            can go to{" "}
            <a href="/" className={style.rev}>
              My reservation
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
