import React from "react";
import style from "./success.module.css";
const SuccessSmile = ({ userId }) => {
  return (
    <div>
      <div className={style.imgContainer}></div>
      <div className="container pt-5">
        <p className="mx-0 p-0 mb-2 text-center fw-bolder fs-2">
          congratulations !!!
        </p>
        <p className="mx-0 p-0 mb-2 text-center fw-bolder fs-5 text-black-50">
          your order booked succfully
        </p>
      </div>
    </div>
  );
};

export default SuccessSmile;
