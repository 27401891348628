import React from "react";
import style from "./BestProgramers.module.css";
import MainTitle from "../../utilites/MainTitile/MainTitle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const BestProgramms = ({
  mainTitle,
  revers,
  showTitle,
  mainDesc,
  showBtn,
  title,
  img,
  bg,
  desc,
}) => {
  const lang = useSelector((state) => state.langSlice.lang);

  return (
    <div className={bg ? "bg" : ""}>
      <div className="pt-5 container">
        {showTitle && <MainTitle title={mainTitle} desc={mainDesc} />}
        {revers ? (
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center">
              <img
                src={img}
                alt="desc/img"
                className={style.mainImg}
                data-aos="zoom-in"
              />
            </div>
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <div className="d-flex flex-column gap-4 justify-content-center align-items-center">
                <h3
                  data-aos="fade-down"
                  data-aos-delay="500"
                  className="fs-4 lh mb-md-0 text-center"
                >
                  {title}
                </h3>
                <p className="mb-3">{desc}</p>
                <button
                  data-aos="fade-up"
                  data-aos-delay="600"
                  className="btn mx-auto d-flex justify-content-center"
                >
                  <Link to="/">
                    {lang === "en" ? "join toady" : "انضم الان"}
                  </Link>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <div className="d-flex flex-column gap-4 justify-content-center align-items-center">
                <h3
                  data-aos="fade-down"
                  data-aos-delay="500"
                  className="fs-4 lh mb-md-0 text-center"
                >
                  {title}
                </h3>
                <p className="mb-3">{desc}</p>
                <button
                  data-aos="fade-up"
                  data-aos-delay="600"
                  className="btn mx-auto d-flex justify-content-center"
                >
                  <Link to="/">
                    {lang === "en" ? "join toady" : "انضم الان"}
                  </Link>
                </button>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <img
                src={img}
                alt="desc/img"
                className={style.mainImg}
                data-aos="zoom-in"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BestProgramms;

/*
    <div className="pt-5">
      {showTitle && (
        <MainTitle
          title="the most popular programs"
          desc="lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem loem lorem lorem lorem lorem lorem lorem lorem lorem lorem l orem lorem loreml oreml loreml o"
        />
      )}

      <div className="container">
        {revers ? (
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <img
                src={img}
                alt="desc/img"
                className={style.mainImg}
                data-aos="flip-up"
              />
            </div>
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <div className="d-flex flex-column gap-4 justify-content-center align-items-center">
                <h3
                  data-aos="fade-down-right"
                  data-aos-delay="500"
                  className="fs-4 lh mb-md-0 text-center"
                >
                  {title}
                </h3>
                <button
                  data-aos="fade-up"
                  data-aos-delay="600"
                  className="btn mx-auto d-flex justify-content-center"
                >
                  <Link to="/">Join today</Link>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center align-items-center container">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <div className="d-flex flex-column gap-4 justify-content-center align-items-center">
                <h3
                  data-aos="fade-down-right"
                  data-aos-delay="500"
                  className="fs-4 lh mb-md-0 text-center"
                >
                  {title}
                </h3>
                <button
                  data-aos="fade-up"
                  data-aos-delay="600"
                  className="btn mx-auto d-flex justify-content-center"
                >
                  <Link to="/">Join today</Link>
                </button>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <img
                src={img}
                alt="desc/img"
                className={style.mainImg}
                data-aos="flip-up"
              />
            </div>
          </div>
        )}

        <div data-aos="fade-right" data-aos-offset="80" className="mt-5 pt-5">
          {showBtn && (
            <button className="btn d-flex justify-content-center mx-auto ">
              <Link to="/workout">All Programms</Link>
            </button>
          )}
        </div>
      </div>
    </div>
*/
