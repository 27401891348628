import React from "react";
import HeroCheck from "../Components/Checkout/hero/HeroCheck";
import CheckForm from "../Components/Checkout/Form/CheckForm";

const Checkout = () => {
  return (
    <div>
      <HeroCheck />
      <div className="container">
        <CheckForm />
      </div>
    </div>
  );
};

export default Checkout;
