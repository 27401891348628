import React, { useState } from "react";
import style from "./paymentForm.module.css";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { request } from "../../utilites/axios";
import { useSelector } from "react-redux";
// icons
import cardIcon from "../../../assets/cardIcon.svg";
import dateIcon from "../../../assets/dateIcon.svg";
const PaymentForm = () => {
  const lang = useSelector((state) => state.langSlice.lang);

  // handle change value
  const [cardNumber, setCardNumber] = useState("");
  const [date, setDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [showCardNumIcon, setShowCardNumIcon] = useState(true);
  const [showDateIcon, setShowDateIcon] = useState(true);
  const [showCvvIcon, setShowCvvIcon] = useState(true);

  //   handle format change
  const [formattedCardNumber, setFormattedCardNumber] = useState("");

  //   handle validation
  const [validCardNumber, setValidCardNumber] = useState(null);

  const validateCardNumber = (number) => {
    return /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(
      number
    );
  };
  const handleCardNumberChange = (e) => {
    const newCardNumber = e.target.value.replace(/\D/g, "");
    const formattedNumber = newCardNumber.replace(/(\d{4})(?=\d)/g, "$1-");
    setCardNumber(newCardNumber);
    setFormattedCardNumber(formattedNumber);
    const isValidCardNum = validateCardNumber(newCardNumber);
    setValidCardNumber(isValidCardNum);
    if (cardNumber.trim() !== "") {
      setShowCardNumIcon(false);
    } else {
      setShowCardNumIcon(true);
    }
  };
  const handleCvvChange = (e) => {
    setCvv(e.target.value);
    if (cvv.trim() !== "") {
      setShowCvvIcon(false);
    } else {
      setShowCvvIcon(true);
    }
  };
  const handleDateChnage = (e) => {
    setDate(e.target.value);
    if (date.trim() !== "") {
      setShowDateIcon(false);
    } else {
      setShowDateIcon(true);
    }
  };
  //   send data to api
  const addCard = (data) => {
    return request({ url: "/", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(addCard, {
    onSuccess: (data) => {
      if (data?.data.status && lang === "en") {
        toast.success("your card added succesfully");
        setCardNumber("");
        setCvv("");
        setDate("");
      } else {
        toast.success("تم اضافة بيانات الكارت بنجاح");
        setCardNumber("");
        setCvv("");
        setDate("");
      }
    },
    onError: () => {
      if (lang === "en") {
        toast.error("there is an error ocuured , please try again");
      } else {
        toast.error("حدث خطأ في ارسال البياتات . من فضلك حاول مرة اخري");
      }
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (cardNumber.trim() === "" || date.trim() === "" || cvv.trim() === "") {
      return;
    } else {
      const userData = { cardNumber, date, cvv };
      mutate(userData);
    }
  };
  return (
    <div className="container py-5">
      <form onSubmit={handleClick}>
        <div className="d-flex align-items-center gap-4 flex-wrap">
          <div>
            <label className={`d-block mb-2 ${style.label}`}>card number</label>
            <div className={style.inpContainer}>
              <input
                placeholder="card number"
                type="number"
                value={cardNumber}
                onChange={handleCardNumberChange}
                className={style.inp}
              />
              {showCardNumIcon && (
                <img
                  alt="cardNumIcon"
                  src={cardIcon}
                  className={style.icon}
                  loading="lazy"
                />
              )}
            </div>
          </div>
          <div>
            <label className={`d-block mb-2 ${style.label}`}>expire date</label>
            <div className={style.inpContainer}>
              <input
                type="number"
                value={date}
                onChange={handleDateChnage}
                className={style.inp}
                placeholder="mm/yy"
              />
              {showCardNumIcon && (
                <img
                  alt="cardNumIcon"
                  src={dateIcon}
                  className={style.icon}
                  loading="lazy"
                />
              )}
            </div>
          </div>
          <div>
            <label className={`d-block mb-2 ${style.label}`}>cvv</label>
            <div className={style.inpContainer}>
              <input
                placeholder="cvv"
                type="number"
                value={cvv}
                onChange={handleCvvChange}
                className={style.inp}
              />
              {showCardNumIcon && (
                <img
                  alt="cardNumIcon"
                  src={cardIcon}
                  className={style.icon}
                  loading="lazy"
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
