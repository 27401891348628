import React, { useState } from "react";
import BookingSummary from "../Components/utilites/bookingSummary/BookingSummary";
import Progress from "../Components/utilites/progress/Progress";
import BookingRules from "../Components/utilites/bookingRules/BookingRules";
import Info from "../Components/billing/info/Info";

const Billing = () => {
  const [firstName, setFirstName] = useState("");
  const [showFirstNameIcon, setShowFirstNameIcon] = useState(true);
  const [lastName, setLastName] = useState("");
  const [showLastNameIcon, setShowLastNameIcon] = useState(true);
  const [phoneValue, setPhone] = useState("");
  const [showPhoneIcon, setShowPhoneIcon] = useState(true);
  const [emailValue, setEmail] = useState("");
  const [showEmailIcon, setShowEmailIcon] = useState(true);

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
    if (firstName.trim() !== "") {
      setShowFirstNameIcon(false);
    } else {
      setShowFirstNameIcon(true);
    }
  };
  const handleChangeLasttName = (e) => {
    setLastName(e.target.value);
    if (lastName.trim() !== "") {
      setShowLastNameIcon(false);
    } else {
      setShowLastNameIcon(true);
    }
  };
  const handleChangePhone = (e) => {
    setPhone(e.target.value);
    if (phoneValue.trim() !== "") {
      setShowPhoneIcon(false);
    } else {
      setShowPhoneIcon(true);
    }
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (emailValue.trim() !== "") {
      setShowEmailIcon(false);
    } else {
      setShowEmailIcon(true);
    }
  };
  return (
    <div className="billing_container container mt">
      <Progress />
      <div className="row mt-5">
        <div className="col-12 col-md-8 mb-3 mb-md-0">
          <Info
            handleChangeFirstName={handleChangeFirstName}
            handleChangeLasttName={handleChangeLasttName}
            handleChangePhone={handleChangePhone}
            handleChangeEmail={handleChangeEmail}
            showFirstNameIcon={showFirstNameIcon}
            showLastNameIcon={showLastNameIcon}
            showPhoneIcon={showPhoneIcon}
            showEmailIcon={showEmailIcon}
          />

          <BookingRules />
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <BookingSummary
            firstName={firstName}
            lastName={lastName}
            emailValue={emailValue}
            phoneValue={phoneValue}
            isConfirm={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Billing;
/*
   <div className="row mt-5">
        <div >
         
        
        </div>
        
      </div>
*/
