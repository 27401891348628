import React, { useState } from "react";
import style from "./onlineForm.module.css";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { request } from "../../utilites/axios";
import Spinner from "../../utilites/Spinner/Spinner.jsx";
import toast from "react-hot-toast";
const OnlineForm = ({ data }) => {
  const [requestSent, setRequestSent] = useState(false);
  const lang = useSelector((state) => state.langSlice.lang);
  const [msg, setMsg] = useState("");
  const [planId, setPlanId] = useState("");
  const [activeCoast, setActiveCoast] = useState(null);
  const handlePlanChange = (e) => {
    setPlanId(e.target.value);
    const selectedOption = e.target.options[e.target.selectedIndex];
    setActiveCoast(selectedOption.getAttribute("data-coast"));
  };
  const user = JSON.parse(localStorage.getItem("userId"));
  const handleLogin = (data) => {
    const headers = {
      userId: user,
      planId,
    };
    return request({
      url: "/onlineCoaching/request",
      method: "post",
      data,
      headers,
    });
  };
  const { isLoading, mutate } = useMutation(handleLogin, {
    onSuccess: (data) => {
      console.log("this is the data", data);
      if (data?.data.status === "success" && lang === "en") {
        toast.success("your Request  sent succfully");
        setRequestSent(true);
      } else if (data?.data.status === "success" && lang === "ar") {
        toast.success("تم ارسال  طلبك بنجاح");
        setRequestSent(true);
      } else if (data?.data.status === "faild" && lang === "en") {
        toast.error("there is an error occured , please try again");
      } else {
        toast.error("حدث خطأ من فضلك حاول مرة اخري");
      }
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (msg.trim() === "" || planId === "") {
      toast.error("all field are required");
      return false;
    } else if (!user) {
      toast.error("you need to login");
    } else if (user) {
      const data = { goals: msg, planId };
      console.log("this is the mutated data", data);
      mutate(data);
    }
  };
  return (
    <>
      <div className="row justify-content-center">
        <div
          data-aos="zoom-in"
          data-aos-delay="500"
          className={`py-3 container col-10 ${style.formContainer}`}
        >
          <div className="px-5">
            <h3
              data-aos="fade-down"
              data-aos-delay="300"
              className="fs-4 fw-bolder mb-3"
            >
              {lang === "en"
                ? "sign up for online coaching"
                : "قم بالتسجيل للحصول على التدريب عبر الإنترنت"}
            </h3>
            {!requestSent ? (
              <form onSubmit={handleClick}>
                <div className="mb-3 row align-items-center">
                  <div className="col-9">
                    <label className={style.label} htmlFor="plans">
                      {lang === "en" ? "plans" : "الباقات المتاحة"}
                    </label>
                    <select
                      onChange={(e) => handlePlanChange(e)}
                      id="plans"
                      className={`${style.inp}`}
                    >
                      <option value=" " disabled>
                        choose your plan
                      </option>
                      {data.map((item, index) => (
                        <option
                          value={item.id}
                          className="text-black"
                          key={index}
                          data-coast={item.duration_cost}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {activeCoast ? (
                    <div className={`col-3 mt-4 ${style.coast}`}>
                      {activeCoast} USD
                    </div>
                  ) : null}
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6 mb-2 mb-md-0">
                    <label htmlFor="name" className={style.label}>
                      name
                    </label>
                    <input type="text" className={style.inp} />
                  </div>
                  <div className="col-12 col-md-6 mb-2 mb-md-0">
                    <label htmlFor="email" className={style.label}>
                      email
                    </label>
                    <input type="email" className={style.inp} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6 mb-2 mb-md-0">
                    <label htmlFor="Whatsapp" className={style.label}>
                      Whatsapp number
                    </label>
                    <input id="Whatsapp" type="text" className={style.inp} />
                  </div>
                  <div className="col-12 col-md-6 mb-2 mb-md-0">
                    <label htmlFor="date" className={style.label}>
                      date of birth
                    </label>
                    <input type="date" id="date" className={style.inp} />
                  </div>
                </div>
                <label className={style.label} htmlFor="msg">
                  why would you like to have
                </label>
                <textarea
                  onChange={(e) => setMsg(e.target.value)}
                  id="msg"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                  className={style.area}
                  placeholder={`${
                    lang === "en" ? "leave your message" : "اترك رسالة"
                  }`}
                ></textarea>
                <button
                  type="submit"
                  className="d-flex justify-content-center btn mx-auto text-center fw-bold my-3 text-white"
                >
                  {lang === "en" ? "Subscribe" : "الاشتراك"}
                </button>
              </form>
            ) : (
              <div>
                <h5 className="my-4 text-center">your request is Pending</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineForm;
/**
 *    <div className="row justify-content-center mb-3">
              <div
                data-aos="fade-down"
                data-aos-delay="400"
                className="col-12 col-md-6 mb-3 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="fn"
                >
                  {lang === "en" ? "first name" : "الاسم الاول"}
                </label>
                <input
                  onChange={(e) => setFirstName(e.target.value)}
                  id="fn"
                  placeholder={`${
                    lang === "en" ? "Enter Your first Name" : "ادخل الاسم الاول"
                  }`}
                  className={style.inp}
                />
              </div>
              <div
                data-aos="fade-up"
                data-aos-delay="500"
                className="col-12 col-md-6 mb-3 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="ln"
                >
                  {lang === "en" ? "   last name" : "الاسم الاخير"}
                </label>
                <input
                  onChange={(e) => setLastName(e.target.value)}
                  id="ln"
                  placeholder={`${
                    lang === "en" ? "Enter Your Last Name" : "ادخل الاسم الاخير"
                  }`}
                  className={style.inp}
                />
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div
                data-aos="fade-down"
                data-aos-delay="600"
                className="col-12 col-md-6 mb-3 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="mail"
                >
                  {lang === "en" ? "Email" : "البريد الالكنروني"}
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={`${
                    lang === "en" ? "Enter Your Email" : "ادخل الايميل"
                  }`}
                  type="email"
                  id="fn"
                  className={style.inp}
                />
              </div>
              <div
                data-aos="fade-up"
                data-aos-delay="700"
                className="col-12 col-md-6 mb-3 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="phone"
                >
                  {lang === "en" ? "phone number" : "رقم الموبايل"}
                </label>
                <input
                  onChange={(e) => setNum(e.target.value)}
                  type="tel"
                  id="ln"
                  placeholder={`${
                    lang === "en"
                      ? "Enter Your Whatsapp Number"
                      : "ادخل رقم الموبايل"
                  }`}
                  className={style.inp}
                />
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              <div
                data-aos="zoom-in"
                data-aos-delay="800"
                className="col-12 col-md-6 mb-3 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="date"
                >
                  {lang === "en" ? "Date of Birth" : "تاريخ الميلاد"}
                </label>
                <input
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  id="date"
                  placeholder={`${
                    lang === "en"
                      ? "Enter Your Date of Birth"
                      : "ادخل تاريخ الميلاد"
                  }`}
                  className={style.inp}
                />
              </div>
              <div
                data-aos="zoom-in"
                data-aos-delay="900"
                className="col-12 col-md-6 mb-3 mb-md-0"
              >
                <label
                  className={`${style.label} fw-bold d-block mb-1`}
                  htmlFor="Place"
                >
                  {lang === "en" ? "place" : "المكان"}
                </label>
                <input
                  onChange={(e) => setPlace(e.target.value)}
                  type="text"
                  id="Place"
                  placeholder={`${
                    lang === "en"
                      ? "Enter Your Place of Birth"
                      : "ادخل مكان الميلاد"
                  }`}
                  className={style.inp}
                />
              </div>
            </div>
 * 
 */
