import React from "react";
import AuthImg from "../Components/utilites/authImg/AuthImg.jsx";
import AuthIntro from "../Components/utilites/authIntro/AuthIntro.jsx";
import RegForm from "../Components/reg/RegForm.jsx";
import SocialAuth from "../Components/utilites/socialAuth/SocialAuth.jsx";
import { useSelector } from "react-redux";
const Reg = () => {
  const lang = useSelector((state) => state.langSlice.lang);
  return (
    <div className="container pt-2 mt mb-5">
      <div className="row justify-content-center gap-2">
        <div className="col-12 col-md-5 mb-md-0">
          <AuthImg />
        </div>
        <div className="col-12 col-md-6  mb-md-0">
          <AuthIntro
            question={
              lang === "en"
                ? "already have an account ? "
                : "هل لديك حساب بالفعل ؟ "
            }
            link={lang === "en" ? "login" : "تسجيل دخول"}
            path="login"
            title={lang === "en" ? "welcome to fitness!" : "مرحبا بك في فيتنس"}
            desc={lang === "en" ? "regester your account" : "قم بإنشاء حساب"}
          />
          <RegForm />
          <SocialAuth intro="create account with" />
        </div>
      </div>
    </div>
  );
};

export default Reg;
