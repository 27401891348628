import React from "react";
import style from "./transformationCard.module.css";
import { Link } from "react-router-dom";
const TransformationCard = ({ item, index }) => {
  return (
    <div
      data-aos="zoom-in"
      data-aos-offset="50"
      data-aos-delay="300"
      key={index}
      className={`${style.mainContainer} mb-5 col-12 col-md-6 col-lg-4`}
    >
      <div className={`w-100  mb-5 ${style.imgContainer}`}>
        <Link to={`/online/${item.id}`}>
          <img
            data-aos="zoom-in"
            alt="transformation/img"
            src={item.photo}
            className="w-100 h-100 img-fluid"
          />
        </Link>

        <div className={style.borderLeft}></div>
        <div className={style.borderRight}></div>
      </div>

      <p
        className={`text-center text-uppercase mb-3 p-0 mt-0 mx-0 ${style.ready}`}
      >
        {item.intro} <span>&#128170;</span>
      </p>
      <Link className={style.titleLink} to={`/online/${item.id}`}>
        <h2 className="text-center text-black-50 fs-2 fw-bolder p-0 mt-0 mx-0 mb-3">
          {item.user_name}
        </h2>
      </Link>
    </div>
  );
};

export default TransformationCard;
