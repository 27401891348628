import React, { useState } from "react";
import style from "./filterbyCategory.module.css";
import AllWorkouts from "../../AllWorkouts/AllWorkouts";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { request } from "../../../utilites/axios";
import Spinner from "../../../../Components/utilites/Spinner/Spinner";
const FilterByCategory = ({ workoutData }) => {
  const [showFilter, setShowFilter] = useState(false);
  const lang = useSelector((state) => state.langSlice.lang);
  const fetchData = () => {
    return request({ url: "/workouts/categories" });
  };
  const { data, isLoading } = useQuery("work page", fetchData, {
    onSuccess: (data) => console.log("hello from home page ", data.data),
  });
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="py-5 container">
          <div className="row justify-content-center">
            <div
              className={`d-none d-md-block col-md-4 col-lg-3 ${style.filterContainer}`}
            >
              {/*larg screen view*/}
              <div className="d-none d-md-block">
                <div className="">
                  <div className="mb-3">
                    <p className=" fw-bold mb-3">
                      {lang === "en" ? "workouts type" : "انواع التمارين"}
                    </p>
                    <div className="mb-3">
                      <input type="checkbox" name="type" id="home" />
                      <label
                        htmlFor="home"
                        className="d-inline-block mx-2 fw-bold text-uppercase"
                      >
                        {lang === "en" ? "home" : "في المنزل"}
                      </label>
                    </div>
                    <div className="mb-3">
                      <input type="checkbox" name="type" id="gym" />
                      <label
                        htmlFor="gym"
                        className="d-inline-block mx-2 fw-bold text-uppercase"
                      >
                        {lang === "en" ? "gym" : "في الجيم"}
                      </label>
                    </div>
                  </div>
                  <div>
                    <p className="fw-bold mb-3">
                      {lang === "en" ? "categories" : "الأقسام"}
                    </p>
                    {data.data.data.map((item, index) => (
                      <div className="mb-3" key={index}>
                        <input
                          value={item.id}
                          type="checkbox"
                          name="category"
                          id="one"
                        />
                        <label
                          htmlFor="one"
                          className="d-inline-block mx-2 fw-bold text-uppercase"
                        >
                          {item.name_}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-md-none col-12">
              {/*mobile view*/}
              <div className="d-block d-md-none">
                <div>
                  <button
                    onClick={() => setShowFilter(true)}
                    className="btn text-white d-flex justify-content-center mx-auto fw-bold text-capitalize fs-5 text-center"
                  >
                    {lang === "en" ? "filter" : "البحث"}
                  </button>
                </div>
                <div
                  className={`${style.overlay} ${
                    showFilter ? style.showOverLay : style.hideOverLay
                  }`}
                ></div>
                <div
                  className={`${style.filterSideBar} ${
                    showFilter ? style.show : style.hide
                  }`}
                >
                  <div className="position-relative mb-5 pb-5">
                    <AiOutlineClose
                      className={style.closeIcon}
                      size={30}
                      onClick={() => setShowFilter(false)}
                    />
                  </div>
                  <div className="mb-5">
                    <p className=" fw-bold mb-5">
                      {lang === "en" ? "workouts type" : "انواع التمارين"}
                    </p>
                    <div className="mb-3">
                      <input type="checkbox" name="type" id="home" />
                      <label
                        htmlFor="home"
                        className="d-inline-block mx-2 fw-bold text-uppercase"
                      >
                        {lang === "en" ? "home" : "في المنزل"}
                      </label>
                    </div>
                    <div className="mb-3">
                      <input type="checkbox" name="type" id="gym" />
                      <label
                        htmlFor="gym"
                        className="d-inline-block mx-2 fw-bold text-uppercase"
                      >
                        {lang === "en" ? "gym" : "في الجيم"}
                      </label>
                    </div>
                  </div>
                  <div>
                    <p className=" fw-bold mb-5 ">
                      {lang === "en" ? "categories" : "الأقسام"}
                    </p>
                    {data.data.data.map((item, index) => (
                      <div className="mb-3" key={index}>
                        <input
                          value={item.id}
                          type="checkbox"
                          name="category"
                          id="one"
                        />
                        <label
                          htmlFor="one"
                          className="d-inline-block mx-2 fw-bold text-uppercase"
                        >
                          {item.name_}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 mt-md-0 col-12 col-md-8 col-lg-9">
              <AllWorkouts data={workoutData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterByCategory;
