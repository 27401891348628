import React, { useEffect, useState } from "react";
import Aos from "aos";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home";
import Navbar from "./Components/Layout/Nav/Navbar";
import Footer from "./Components/Layout/footer/Footer";
import Workout from "./Pages/Workout";
import WorkoutDetails from "./Pages/WorkoutDetails";
import OnlineCoaching from "./Pages/OnlineCoaching";
import TransformationDetails from "./Pages/TransformationDetails";
import BackToTopBtn from "./Components/utilites/backToTop/BackToTopBtn";
import { useSelector } from "react-redux";
import Meta from "./Components/utilites/meta/Meta";
import Faq from "./Pages/Faq";
import ExcDetails from "./Pages/ExcDetails";
import Checkout from "./Pages/Checkout";
import Spinner from "./Components/utilites/Spinner/Spinner";
import DietCalc from "./Pages/DietCalc";
import Competations from "./Pages/Competations";
import Competation from "./Pages/Competation";
import Login from "./Pages/Login";
import Reg from "./Pages/Reg";
import Forget from "./Pages/Forget";
import NewPassword from "./Pages/NewPassword";
import OTP from "./Pages/OTP.js";
import Orders from "./Pages/Orders";
import Billing from "./Pages/Billing";
import Payment from "./Pages/Payment";
import { QueryClientProvider, QueryClient } from "react-query";
import Success from "./Pages/Success";
import Cancel from "./Pages/Cancel";

function App() {
  const queryClient = new QueryClient();
  const userId = JSON.parse(window.localStorage.getItem("userId"));
  const [loading, setLoading] = useState(true);
  // handle show spinner
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  });
  const lang = useSelector((state) => state.langSlice.lang);
  // handle animation effect
  useEffect(() => {
    Aos.init({
      offset: 0,
      duration: 1200,
    });
  }, []);

  // handle scroll to top
  function ScrollToTopAfterChangePage() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }
  // handle change direction when the language change
  useEffect(() => {
    document.documentElement.setAttribute("lang", lang);
    if (lang === "ar") {
      document.getElementsByTagName("body")[0].style.direction = "rtl";
    } else {
      document.getElementsByTagName("body")[0].style.direction = "ltr";
    }
  }, [lang]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Meta />
              <ScrollToTopAfterChangePage />
              <BackToTopBtn />
              <Navbar userId={userId} />
              <Routes>
                <Route path="/" exact element=<Home /> />
              </Routes>
              <Routes>
                <Route path="/workout" exact element=<Workout /> />
              </Routes>
              <Routes>
                <Route path="/workout/:id" element=<WorkoutDetails /> />
              </Routes>

              <Routes>
                <Route path="/online" element=<OnlineCoaching /> />
              </Routes>
              <Routes>
                <Route path="/online/:id" element=<TransformationDetails /> />
              </Routes>
              <Routes>
                <Route path="/faq" element=<Faq /> />
              </Routes>
              <Routes>
                <Route path="/calc" element=<DietCalc /> />
              </Routes>
              <Routes>
                <Route path="/checkout" element=<Checkout /> />
              </Routes>
              <Routes>
                <Route path="/comp" element=<Competations /> />
              </Routes>
              <Routes>
                <Route path="/comp/:id" element=<Competation /> />
              </Routes>
              <Routes>
                <Route path="/login" element=<Login /> />
              </Routes>
              <Routes>
                <Route path="/reg" element=<Reg /> />
              </Routes>
              <Routes>
                <Route path="/forget" element=<Forget /> />
              </Routes>
              <Routes>
                <Route path="/new-password" element={<NewPassword />} />
              </Routes>
              <Routes>
                <Route path="/otp" element={<OTP />} />
              </Routes>
              <Routes>
                <Route path="/orders" element={<Orders lang={lang} />} />
              </Routes>
              <Routes>
                <Route path="/billing" element={<Billing />} />
              </Routes>
              <Routes>
                <Route path="/payment" element={<Payment />} />
              </Routes>
              <Routes>
                <Route path="/success/:id" element={<Success />} />
              </Routes>
              <Routes>
                <Route path="/cancel/:id" element={<Cancel />} />
              </Routes>
              <Footer />
            </Router>
          </QueryClientProvider>
        </div>
      )}
    </div>
  );
}

export default App;
