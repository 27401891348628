import React from "react";
import AuthImg from "../Components/utilites/authImg/AuthImg";
import ForgetIntro from "../Components/utilites/forgetIntro/ForgetIntro.jsx";
import OtpForm from "../Components/otp/OtpForm";
const OTP = () => {
  return (
    <div className="container pt-2 mt mb-5">
      <div className="row justify-content-center gap-2">
        <div className="col-12 col-md-5 mb-md-0">
          <AuthImg />
        </div>
        <div className="col-12 col-md-6  mb-md-0">
          <ForgetIntro
            title="Verfication code"
            desc="enter your Verfication code to continue"
          />
          <OtpForm />
        </div>
      </div>
    </div>
  );
};

export default OTP;
