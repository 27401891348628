import React from "react";
import style from "./paymentInfo.module.css";
const PaymentInfo = ({ firstName, lastName, email, phone }) => {
  return (
    <div className="py-3">
      <p className={`mx-0 p-0 mt-0 mb-4 ${style.title}`}>contact information</p>
      <div className="row">
        <div className="col-12 col-md-6 mb-3 mb-md-0 ">
          <div className="d-flex flex-column gap-1 mb-3">
            <p className={`m-0 p-0 ${style.infoTitle}`}>firstName : </p>
            <p className={`${style.info} m-0 p-0`}>{firstName}</p>
          </div>
          <div className="d-flex flex-column gap-1 mb-3">
            <p className={`m-0 p-0 ${style.infoTitle}`}>phone number : </p>
            <p className={`${style.info} m-0 p-0`}>{phone}</p>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3 mb-md-0 ">
          <div className="d-flex flex-column gap-1 mb-3">
            <p className={`m-0 p-0 ${style.infoTitle}`}>last name : </p>
            <p className={`${style.info} m-0 p-0`}>{lastName}</p>
          </div>
          <div className="d-flex flex-column gap-1 mb-3">
            <p className={`m-0 p-0 ${style.infoTitle}`}>email : </p>
            <p className={`${style.info} m-0 p-0`}>{email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
